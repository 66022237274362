import React, {useState} from "react";
import { Link } from "react-router-dom";
import '../../../node_modules/font-awesome/css/font-awesome.min.css'; 
import logo from "../../media/images/LOGO STAR QUADRI.small.png"
import { ButtonCusto } from "../Button/Button";
import { useSelector } from 'react-redux'
import avatar from '../../media/images/people.png'
import { faHouse, faInfoCircle, faCalendarWeek, faMarker , faSchool , faFlagCheckered , faStar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../NavBar/Navbar.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../Button/Button.css';

function Navbar()
{
    const user = useSelector((state) => state.users.name)
    const avatarUser = useSelector((state) => state.users.avatar)

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);


    return (
        
            <nav className="navbar">

                <div className="navbar-container">

                    <Link to="/" className="navbar-logo">
                        <img src={logo} className="brand-logo"/>     
                    </Link> 

                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu nomargin'}>
                        <li className='nav-item nopadding navstar'>
                            
                            <Link to='/' className="nav-links" onClick={closeMenu}>
                            <FontAwesomeIcon icon={faHouse} /> Home
                            </Link>
                        </li>
                        <li className='nav-item nopadding navstar'>
                            <Link to='/informations' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faInfoCircle} /> Informations
                            </Link>
                        </li>
                        <li className='nav-item nopadding navstar'>
                            <Link to='/calendrier' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faCalendarWeek} /> Calendrier
                            </Link>
                        </li>   
                        <li className='nav-item nopadding navstar'>
                            <Link to='/ventes' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faMarker} /> Petites annonces
                            </Link>
                        </li>
                        <li className='nav-item nopadding navstar'>
                            <Link to='/permanence' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faSchool} /> Permanence
                            </Link>
                        </li>   
                        <li className='nav-item nopadding navstar'>
                            <Link to='/examens' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faFlagCheckered} /> Examens
                            </Link>
                        </li>               
                        <li className='nav-item nopadding navstar'>
                            <Link to='/administration' className="nav-links" onClick={closeMenu}>
                                <FontAwesomeIcon icon={faStar} /> Administration
                            </Link>
                        </li>
                        
                    </ul>
                </div>

     
                {user ? 
                (
                    <Link to="/profil" className="buttonlogin">                
                        <img src={avatarUser ? avatarUser : avatar} className="avatar"></img>                               
                    </Link> 
                ) 
                : 
                (
                    <div className="buttonlogin">
                        <ButtonCusto buttonStyle='btn--outline'>CONNECTION</ButtonCusto>
                    </div>
                )}

                

            </nav>
        
        
    )
}

export default Navbar