// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorize {
    width: 90%;
    text-align: center;
}

.vertical-center 
{
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.stop-logo {
    width: 300px;
    height: auto;
}

.btn-retour {
    color: white;
    background-color: blue;
    width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/unauthorizes.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;;IAEI,SAAS;IACT,kBAAkB;IAClB,QAAQ;IAER,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".unauthorize {\r\n    width: 90%;\r\n    text-align: center;\r\n}\r\n\r\n.vertical-center \r\n{\r\n    margin: 0;\r\n    position: absolute;\r\n    top: 50%;\r\n    -ms-transform: translateY(-50%);\r\n    transform: translateY(-50%);\r\n    width: 100%;\r\n}\r\n\r\n.stop-logo {\r\n    width: 300px;\r\n    height: auto;\r\n}\r\n\r\n.btn-retour {\r\n    color: white;\r\n    background-color: blue;\r\n    width: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
