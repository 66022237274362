import * as React from 'react';
import { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import TirControl from '../TirControle/TirControle';
import TirEcole from '../TirEcole/TirEcole';
import TirPlateau from '../TirPlateau/TirPlateau';
import { useSelector } from 'react-redux';

const Calendrier = ({socket}) => {

    const user = useSelector((state) => state.users);

    const [value, setValue] = React.useState('1');

    const errRef = useRef();
    const sucRef = useRef();

    const [errMsg, setErrMsg] = useState('');
    const [sucMsg, setSucMsg] = useState('');

    

    useEffect(() => {
        setErrMsg('');
    }, [])

    useEffect(() => {
        setSucMsg('');
    }, [])


    const handleChange = async(event, newValue) => {
        setValue(newValue);
    };

    return (

    <section className='administrations'>

    <p ref={sucRef} className={sucMsg ? "sucmsg" : "offscreen"} aria-live="assertive">{sucMsg}</p>
    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

    <Box sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={value}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="" className='sticky'>
            <Tab label="Ecole de Tir" value="1" /> 
            <Tab label="Tir Contrôle" value="3" />
            <Tab label="Evenements" value="2" />
            </TabList>
        </Box>


        <TabPanel value="1" className='CalendrierWidth'>
      
            <TirEcole username={user} socket={socket}/>
            
        </TabPanel>

        <TabPanel value="2">
            
            <TirPlateau username={user} socket={socket}/>

        </TabPanel>

        <TabPanel value="3">

            <TirControl username={user} socket={socket}/>

        </TabPanel>


        </TabContext>

    </Box>

    </section>

    );
    }

    export default Calendrier