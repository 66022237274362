import React from "react";
import axios from '../../api/axios';
import { useRef, useState, useEffect } from 'react';
import '../css/quillclass.css'

const GETTEXTACCEUIL_URL = '/tables/getrecord/textacceuil';

export const TextAcceuil = () => {

    const [value, setValue] = useState('');

    useEffect(() => {
        
        axios
          .post(GETTEXTACCEUIL_URL)
          .then((res) => {
            setValue((res.data.rows[0].textacceuil))
          })
    })

    return (

       <td className="fullHorizontal" dangerouslySetInnerHTML={{__html: value}} />

    )
}