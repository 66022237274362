// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabbutton {
    width: 5px;
}

.licenceText {
    text-align: center;
}

.colLicence {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.licenceContainer {
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/TableLicences/TableLicences.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,4BAA4B;IAC5B,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tabbutton {\r\n    width: 5px;\r\n}\r\n\r\n.licenceText {\r\n    text-align: center;\r\n}\r\n\r\n.colLicence {\r\n    padding-right: 0px !important;\r\n    padding-left: 0px !important;\r\n    margin-right: 0px !important;\r\n    margin-left: 0px !important;\r\n}\r\n\r\n.licenceContainer {\r\n    max-width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
