import React from "react";
import { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' 
import frLocale from '@fullcalendar/core/locales/fr';
import './TirPlateau.css'
import axios from 'axios';
import moment from 'moment';

const GETEVENEMENTS_URL = '/tables/getrecord/evenements'

const computeDurations = (mode) => {
    return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{weekday: 'short' , day: '2-digit' }],
        duration: { days: 7 },
    };
};

function TirPlateau({username , socket}) {

    const initialized = useRef(false);
    moment.locale('FR-fr');
    moment().format("YYYY-MM-DD[T]HH:mm:ss");
    const year = moment(new Date()).format("YYYY")
    const [events, setEvents] = useState([]);
    const { duration, slotDuration, slotLabelFormat } = computeDurations();


    useEffect(() => {
        
        socket.on('messageResponse', (data) => {

            console.log("socket : " + data.text )

        });

    }, [socket]);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            loadJoursFeries();
            loadEvents();
          }   
    }, []);


    const loadJoursFeries = async () => {

        axios
          .get('https://calendrier.api.gouv.fr/jours-feries/metropole/'+year+'.json')
          .then((res) => {
            Object.entries(res.data).forEach(([key, value]) => {
                setEvents((events) => [...events , 
                {
                    id:Math.floor(Math.random()*500000),
                    title:value,
                    start:key,
                    color:'#FF000033',
                    display: 'background'
                }])        
            });
        })
    }

    const loadEvents = async () => {
  
        axios
          .post(GETEVENEMENTS_URL)
          .then((res) => {
            Object.entries(res.data.rows).forEach(([key, value]) => {
                setEvents((events) => [...events , 
                {
                    id : Math.floor(Math.random()*50000),
                    title: value.titreevenement,
                    start : value.start + "T" + value.heuredebut,
                    end : value.start + "T" + value.heurefin,
                    color: value.color                          
                }]);
            }); 
        })
    }


    return (

        <div>     
            <h4 className="primary-header Banniere">               
                Evenements
            </h4>
            <div className='calendarTir'>
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    locale={frLocale}
                    headerToolbar={{
                        left: 'prev next today',
                        center: 'title',
                        right: ''
                    }}
                    events={events}
                    slotDuration={slotDuration}
                    slotLabelFormat={slotLabelFormat}
                    duration={duration}
                    hiddenDays={[2,3,4,5]}
                />
            </div>
        </div>
    )
}

export default TirPlateau;