// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary: blue;    
}

.btn {
    padding: 8px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: white;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent !important;
    color: blue !important;
    padding: 8px 20px !important;
    border: 1px solid var(--primary) !important;
    transition: all 0.3s ease-out !important;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background-color: rgb(0, 140, 255);
    color: white;
    transition: all 0.5s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,wCAAwC;IACxC,sBAAsB;IACtB,4BAA4B;IAC5B,2CAA2C;IAC3C,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,6BAA6B;AACjC","sourcesContent":[":root {\r\n    --primary: blue;    \r\n}\r\n\r\n.btn {\r\n    padding: 8px 20px;\r\n    border-radius: 5px;\r\n    outline: none;\r\n    border: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.btn--primary {\r\n    background-color: var(--primary);\r\n    color: white;\r\n    border: 1px solid var(--primary);\r\n}\r\n\r\n.btn--outline {\r\n    background-color: transparent !important;\r\n    color: blue !important;\r\n    padding: 8px 20px !important;\r\n    border: 1px solid var(--primary) !important;\r\n    transition: all 0.3s ease-out !important;\r\n}\r\n\r\n.btn--medium {\r\n    padding: 8px 20px;\r\n    font-size: 20px;\r\n}\r\n\r\n.btn--large {\r\n    padding: 12px 26px;\r\n    font-size: 20px;\r\n}\r\n\r\n.btn--medium:hover, .btn--large:hover {\r\n    background-color: rgb(0, 140, 255);\r\n    color: white;\r\n    transition: all 0.5s ease-out;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
