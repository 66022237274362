// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagebutton
{
    left: 40px !important;
}

.row2
{
    flex-wrap: wrap;
    align-content: normal;
    justify-content: space-evenly;
    align-items: center
}

.card2
{
    height: 140px;
    width: 150px;
    margin-top: 20px;
}

.cardBody2
{
    position: absolute;
    bottom: -10px;
}

.btn2
{
    width: 100px;
    font-size: xx-small;
}

.ContainerImages
{
    text-align: center;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/MediaGestion/MediaGestion.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;AACzB;;AAEA;;IAEI,eAAe;IACf,qBAAqB;IACrB,6BAA6B;IAC7B;AACJ;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;;IAEI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;;IAEI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".imagebutton\r\n{\r\n    left: 40px !important;\r\n}\r\n\r\n.row2\r\n{\r\n    flex-wrap: wrap;\r\n    align-content: normal;\r\n    justify-content: space-evenly;\r\n    align-items: center\r\n}\r\n\r\n.card2\r\n{\r\n    height: 140px;\r\n    width: 150px;\r\n    margin-top: 20px;\r\n}\r\n\r\n.cardBody2\r\n{\r\n    position: absolute;\r\n    bottom: -10px;\r\n}\r\n\r\n.btn2\r\n{\r\n    width: 100px;\r\n    font-size: xx-small;\r\n}\r\n\r\n.ContainerImages\r\n{\r\n    text-align: center;\r\n    margin-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
