import React from "react";
import axios from '../../api/axios';
import { useRef, useState, useEffect , useContext} from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import './annonces.css'
import nophoto from '../../media/images/nophoto.png'
import vendu from '../../media/images/bandeau-vendu.png'
import AccordionContext from 'react-bootstrap/AccordionContext';
import Resizer from "react-image-file-resizer";

const ADDANNONCES_URL = '/tables/addrecord/annonces';
const ADDPHOTOSANNONCES_URL = '/tables/addrecord/photosannonces';
const DELPHOTOSANNONCES_URL = '/tables/delrecord/photosannonces';
const GETTYPEANNONCES_URL = '/tables/getrecord/typeannonce';
const GETANNONCESBYUSER_URL = '/tables/getrecord/annoncesbyuser';
const GETPHOTOSANNONCES_URL = '/tables/getrecord/photosannonces';
const MODANNONCEVENDUE_URL = '/tables/modrecord/annoncesvendue';
const MODANNONCE_URL = '/tables/modrecord/annonces';
const GETANNONCESBYID_URL = '/tables/getrecord/annoncesbyid';
 
const TEXTANNONCE_REGEX = /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ&!"#$%&'()*+,./:;<=>?@\\^_`{|}~._\s-]{2,250}$/;
const PRIX_REGEX = /^[0-9]{1,5}$/;
const TYPE_REGEX = /^[0-9]{1}$/;
const TITRE_REGEX = /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ&!"#$%&'()*+,./:;<=>?@\\^_`{|}~._\s-]{2,30}$/;
const CONTACT_REGEX = /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ&!"#$%&'()*+,./:;<=>?@\\^_`{|}~._\s-]{2,50}$/;

export const MesAnnonces = ({username}) => {

    const [annonceid, setAnnonceid] = useState([]);
    const [refresh, setRefresh] = useState([]);
    const accordionRef = useRef(null);
    const [annonces, setAnnonces] = useState([]);
    const [typearme, setTypearme] = useState([]);
    const [photos, setPhotos] = useState(new Map());
    const myRef = useRef([]);
    const { activeEventKey } = useContext(AccordionContext);

    const [textAnnonce, setTextAnnonce] = useState('');
    const [validTextAnnonce, setValidTextAnnonce] = useState(false);

    const [prix, setPrix] = useState('');
    const [validPrix, setValidPrix] = useState(false);

    const [type, setType] = useState('');
    const [validType, setValidType] = useState(false);

    const [titre, setTitre] = useState('');
    const [validTitre, setValidTitre] = useState(false);

    const [contact, setContact] = useState('');
    const [validContact, setValidContact] = useState(false);

    useEffect(() => {
        
        axios
          .post(GETANNONCESBYUSER_URL,{"userid": username.userid})
          .then((res) => {
            res.data.rows?.map((annonce) => {
            axios
                .post(GETPHOTOSANNONCES_URL,{"id" : annonce.annonceid })
                .then((res) => {
                  setPhotos(map => new Map(map.set(annonce.annonceid, res.data.rows)));
                })
            })
            setAnnonces((res.data.rows))
          })  

        axios
          .post(GETTYPEANNONCES_URL)
          .then((res) => {
            setTypearme((res.data.rows))
        })

    },[refresh])

    const handleVendu = (e) => {
      axios
          .post(MODANNONCEVENDUE_URL,{"id" : e.target.id, "vendue" : true})
          .then((res) => {
            setRefresh(res)
        })
    }

    const handleModifier = async (e) => {
      console.log(activeEventKey)
      const accordion = accordionRef.current;
      accordion.firstChild.click()
      await axios
          .post(GETANNONCESBYID_URL,{"id" : e.target.id})
          .then((res) => {
            setAnnonceid(e.target.id)
            setTextAnnonce(res.data.rows[0].contenu);
            setPrix(res.data.rows[0].prix);
            setType(res.data.rows[0].typearme);
            setTitre(res.data.rows[0].titre);
            setContact(res.data.rows[0].contact); 
        })          
    }

    const resizeFile = (file) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(file,300,300,"JPEG",100,0,(uri) => {
            resolve(uri);
        },
        "base64"
        );
    });

    const imageUpload = async (e) => {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      axios
          .post(ADDPHOTOSANNONCES_URL,{"photo" : image, "annonce" : e.target.id})
          .then((res) => {
            setRefresh(image)
        })
    };

    const handleClickImage = (e) => {
        console.log(e.target.id)
        const myRefs = myRef.current;
        myRefs?.map((ref) => {
          if (ref.id === e.target.id)
          {
            const fileElem = ref
            fileElem.click();
          }
        })
    } 

    const addToRefs = (el) => {
      if (el && !myRef.current.includes(el)) {
        myRef.current.push(el);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (annonceid.length === 0)
      {
        var body = {"contenu" : textAnnonce ,"titre" : titre , "prix" : prix , "vendue" : false , "user" : username.userid , "contact" : contact , "typearme" : type}
        await axios
        .post(ADDANNONCES_URL,body)
        .then((res) => {
          setRefresh(body)
          setTextAnnonce('');
          setPrix('');
          setType('');
          setTitre('');
          setContact('');
        })
      }
      else
      {
        var body = {"id" : annonceid , "contenu" : textAnnonce ,"titre" : titre , "prix" : prix , "vendue" : false , "user" : username.userid , "contact" : contact , "typearme" : type}     
        await axios
        .post(MODANNONCE_URL,body)
        .then((res) => {
          setRefresh(body)
          setAnnonceid('');
          setTextAnnonce('');
          setPrix('');
          setType('');
          setTitre('');
          setContact('');
        })
      }
      
    }

    const imageDelete = async (id) => {   
      var body = {"id" : id}
      console.log(id)
      await axios.post(DELPHOTOSANNONCES_URL,body);
      setRefresh(body)
    };


    useEffect(() => {
      setValidTextAnnonce(TEXTANNONCE_REGEX.test(textAnnonce));
    }, [textAnnonce])

    useEffect(() => {
      setValidPrix(PRIX_REGEX.test(prix));
    }, [prix])

    useEffect(() => {
      setValidType(TYPE_REGEX.test(type));
    }, [type])

    useEffect(() => {
      setValidTitre(TITRE_REGEX.test(titre));
    }, [titre])

    useEffect(() => {
      setValidContact(CONTACT_REGEX.test(contact));
    }, [contact])



    return (
        <>

          <Container className='cardannonceContainer'>
            
            <Row xs={1} className="g-4 row2 ">
            <Accordion  >
            <div className="cardannonce">
    
            {(annonces.length === 0)? (
              <Accordion.Item  eventKey="mesannonces" className="accordionitemannoncegeneral">
              <Accordion.Header ref={accordionRef}>( {annonces.length} ) Mes Petites Annonces </Accordion.Header>
              </Accordion.Item>
            ):(             
              <Accordion.Item  eventKey="ArmeLongue" className="accordionitemannoncegeneral">
              <Accordion.Header ref={accordionRef}>( {annonces.length} ) Mes Petites Annonces </Accordion.Header>
              {annonces?.map((annonce,index) => {
                return (
                  <Accordion.Body className='accordionannoncegeneral'>
                      <Card className='cardannoncegeneral'>
                      <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                      <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                      <Card.Body className='cardannoncebody'>
                        <Carousel fade className="cardannoncecarroussel" indicators={false}>
                        {((photos.get(annonce.annonceid))?.length !== 0)? 
                        (
                          photos.get(annonce.annonceid)?.map((photo) => {
                            return (                   
                              <Carousel.Item>
                                  <img className="cardannonceimgfit" src={photo.photo}/>
                                  <Button className='btn2 boutonimageannonce' variant="danger" onClick={() => imageDelete(photo.photoid)}>Delete Image</Button>
                              </Carousel.Item>
                            )
                          })              
                        ):(                     
                            <Carousel.Item>
                                <img src={nophoto} className="nophoto"></img>   
                            </Carousel.Item>
                        )}
                        </Carousel> 
                        <div className="cardannoncetext">
                        {annonce.contenu}
                        </div>

                        <div className="buttonannonceedit">
                        {((!annonce.vendue))? 
                        (   
                          <div>                       
                          <Button className='bouttonannonce' id={annonce.annonceid}  onClick={handleClickImage} variant="secondary">Ajouter Photos</Button>
                          <input className='imagebutton' type="file" ref={addToRefs}  id={annonce.annonceid} name='imageFile' onChange={imageUpload} style={{display: 'none'}} />                          
                          <Button className='bouttonannonce' id={annonce.annonceid} onClick={handleVendu} variant="success">Vendu !</Button>                       
                          <Button className='bouttonannonce' id={annonce.annonceid} onClick={handleModifier} variant="warning">Modifier</Button>
                          </div>
                        ):(
                          <img src={vendu} className="vendu"></img>   
                        )}  
                        </div>

                      </Card.Body>
                      <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
                  </Card>
                  </Accordion.Body>         
                )
              })}
              </Accordion.Item>      
              )}

            </div>
            </Accordion>      
            </Row>

              

          </Container>

          <Container className='cardannonceContainer spacercenter'>

            <div> Creation ou modification d'une annonce </div>
            <div> Les photos pourront etre ajoutées et supprimées après la création de l'annonce </div>
            <br/>
            <Form onSubmit={handleSubmit}>


                <label htmlFor="titre" className='texteannoncelabel'>
                    Titre :
                    <FontAwesomeIcon icon={faCheck} className={validTitre ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validTitre || !titre ? "hide" : "invalid"} />
                </label>          
                <input 
                    className='titre'
                    type="text"
                    id="titre"
                    autoComplete="off"
                    onChange={(e) => setTitre(e.target.value)}
                    value={titre}
                    required
                    aria-invalid={validTitre ? "false" : "true"}
                    aria-describedby="uidnote"
                />

                <br/>  

                <label htmlFor="type" className='texteannoncelabel'>
                    Type :
                    <FontAwesomeIcon icon={faCheck} className={validType ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validType || !type ? "hide" : "invalid"} />
                </label>    
                <Select className='type' labelId="type" label="Type" onChange={(e) => setType(e.target.value)} aria-invalid={validType ? "false" : "true"} >       
                    {
                    typearme?.map((type) => {
                    return (  
                      <MenuItem value={type.typeannonceid}>{type.type}</MenuItem>        
                    )
                    })}              
                </Select>

                <br/>             

                <label htmlFor="texteannonce" className='texteannoncelabel'>
                    Texte de l'annonce :
                    <FontAwesomeIcon icon={faCheck} className={validTextAnnonce ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validTextAnnonce || !textAnnonce ? "hide" : "invalid"} />
                </label>          
                <textarea 
                    className='texteannonce'
                    type="text"
                    id="texteannonce"
                    autoComplete="off"
                    onChange={(e) => setTextAnnonce(e.target.value)}
                    value={textAnnonce}
                    required
                    aria-invalid={validTextAnnonce ? "false" : "true"}
                    aria-describedby="uidnote"
                />

                <br/>

                <label htmlFor="prix" className='texteannoncelabel'>
                    Prix :
                    <FontAwesomeIcon icon={faCheck} className={validPrix ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validPrix || !prix ? "hide" : "invalid"} />
                </label>          
                <input 
                    className='prix'
                    type="text"
                    id="prix"
                    autoComplete="off"
                    onChange={(e) => setPrix(e.target.value)}
                    value={prix}
                    required
                    aria-invalid={validPrix ? "false" : "true"}
                    aria-describedby="uidnote"
                />

                <br/>

                <label htmlFor="contact" className='texteannoncelabel'>
                    Contact :
                    <FontAwesomeIcon icon={faCheck} className={validContact ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validContact || !contact ? "hide" : "invalid"} />
                </label>          
                <input 
                    className='contact'
                    type="text"
                    id="contact"
                    autoComplete="off"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                    required
                    aria-invalid={validContact ? "false" : "true"}
                    aria-describedby="uidnote"
                />
                
                <br/>

                <button className='buttonFormLogin' disabled={!setValidPrix || !setValidType || !setValidTitre || !setValidContact  ? true : false}>Enregistrement</button>


            </Form> 

          </Container>

        </>

    )
}