import { useNavigate } from "react-router-dom"
import '../css/unauthorizes.css';
import stop from "../../media/images/stop.png"
import Button from 'react-bootstrap/Button';

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <section className="unauthorize">
            <div className="vertical-center">
                
                <h1>Non autorisé</h1>
                <br />
                <img src={stop} className="stop-logo"/> 
                <br />  
                <p>Vous n'avez pas le droit d'acceder à cette page</p>
                <br />  
                <div className="flexGrow">
                    <Button variant="warning" onClick={goBack} size="lg" className="btn-retour">Retour</Button>{' '}
                  
                </div>
            </div>
        </section>
    )
}

export default Unauthorized