// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register {
    width: 100%;
    text-align: center;
    margin-top: 400px;
    position: relative;
}

.buttonFormLogin {
    text-align: center;
    margin: 20px;
    width: 210px;
    border-radius: 10px;
    background-color: blue;
    color: white;
    height: 40px;
  
}

.infoInput {
    border-radius: 10px;
    height: 40px;
    padding: 10px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.sucmsg {
    background-color: lightgreen;
    color: green;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/register.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,4BAA4B;IAC5B,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".register {\r\n    width: 100%;\r\n    text-align: center;\r\n    margin-top: 400px;\r\n    position: relative;\r\n}\r\n\r\n.buttonFormLogin {\r\n    text-align: center;\r\n    margin: 20px;\r\n    width: 210px;\r\n    border-radius: 10px;\r\n    background-color: blue;\r\n    color: white;\r\n    height: 40px;\r\n  \r\n}\r\n\r\n.infoInput {\r\n    border-radius: 10px;\r\n    height: 40px;\r\n    padding: 10px;\r\n}\r\n\r\n.hide {\r\n    display: none;\r\n}\r\n\r\n.valid {\r\n    color: limegreen;\r\n    margin-left: 0.25rem;\r\n}\r\n\r\n.invalid {\r\n    color: red;\r\n    margin-left: 0.25rem;\r\n}\r\n\r\n.errmsg {\r\n    background-color: lightpink;\r\n    color: firebrick;\r\n    font-weight: bold;\r\n    padding: 0.5rem;\r\n    margin-bottom: 0.5rem;\r\n    position: absolute;\r\n    width: 100%;\r\n    z-index: 2;\r\n}\r\n\r\n.sucmsg {\r\n    background-color: lightgreen;\r\n    color: green;\r\n    font-weight: bold;\r\n    padding: 0.5rem;\r\n    margin-bottom: 0.5rem;\r\n    position: absolute;\r\n    width: 100%;\r\n    z-index: 2;\r\n}\r\n\r\n.instructions > svg {\r\n    margin-right: 0.25rem;\r\n}\r\n\r\n.offscreen {\r\n    position: absolute;\r\n    left: -9999px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
