import { Outlet } from "react-router-dom"

import '../css/layout.css';

import { Carousell } from '../Carousell/carousell'
import { Meteo } from '../Meteo/Meteo'
import { TextAcceuil } from '../TextAcceuil/TextAcceuil'
import { Evenements } from '../Evenements/Evenements'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const Layout = () => {

    return (

        <main className="App">

    <Container className="fill">
      <Row className="carrousselAcceuil"> 
        <Col>

            <Carousell />

        </Col>
      </Row>
      <Row>
        <Col>

            <Meteo />

        </Col>
      </Row>
      <Row>
        <Col>

            <Evenements />

        </Col>
      </Row>
      <Row>
        <Col>

            <TextAcceuil />

        </Col>
      </Row>
    </Container>

    
        
            
    <Outlet />
         
        </main>
    )
}

export default Layout