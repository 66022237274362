import * as React from 'react'
import { useRef, useState, useEffect } from 'react';
import axios from '../../api/axios';
import emailjs from '@emailjs/browser'

const RECOVER_URL = '/tables/getrecover/user';

const ForgotPassword = () => {

  const errRef = useRef();
  const sucRef = useRef();
  
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [pwd, setPwd] = useState('');
  const [emailFrom, setEmailFrom] = useState('');

  const [mail, setMail] = useState('');
  const [licence, setLicence] = useState('');

  const [errMsg, setErrMsg] = useState('');
  const [sucMsg, setSucMsg] = useState('');


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_nvvytvk' , 'template_v1nwme5' , e.target , 'N5rkV6C7dY-4mluUT');
    setSucMsg('Mail de récupperation envoyé');

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var body = {"mail" : mail,"licence" : licence}
    try {
      axios
          .post(RECOVER_URL,body )
          .then((res) => {

            e.target.nom.value=res?.data.rows[0].nom;
            e.target.prenom.value=res?.data.rows[0].prenom;
            e.target.pwd.value=res?.data.rows[0].password;
            e.target.email_from.value="star.stpourcinoise@gmail.fr";

            sendEmail(e);
          })
          .catch(error => {
            setErrMsg('Erreur de mail ou de licence');
      })
    }
    catch (err) 
    {  
        setErrMsg('No Server Response');       
        errRef.current.focus();    
    }
  }

  return (

    
    <>

      <p ref={sucRef} className={sucMsg ? "sucmsg" : "offscreen"} aria-live="assertive">{sucMsg}</p>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      <article style={{ padding: "100px" }}>
          <h1>Récuperation du mot de passe</h1>
          <p>&nbsp;</p>
          <p>Si vous êtes enregistré, un mail vous sera envoyé avec votre ancien mot de passe qu'il faudra modifier a votre prochaine connection</p>
          <p>&nbsp;</p>
          <form onSubmit={handleSubmit} style={{ margin: "20px" }}>

                <label htmlFor="mail">email :</label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="mail"
                    name="mail"
                    autoComplete="off"
                    onChange={(e) => setMail(e.target.value)}
                    value={mail}
                    required
                />
                <br/>
                <label htmlFor="licence">Licence:</label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="licence"
                    name="licence"
                    onChange={(e) => setLicence(e.target.value)}
                    value={licence}
                    required
                />
                <br/>
                <input
                    className='infoInput'
                    type="hidden"
                    id="nom"
                    name="nom"
                    value={nom}
                />
                <input
                    className='infoInput'
                    type="hidden"
                    id="prenom"
                    name="prenom"
                    value={prenom}
                />
                <input
                    className='infoInput'
                    type="hidden"
                    id="pwd"
                    name="pwd"
                    value={pwd}
                />
                <input
                    className='infoInput'
                    type="hidden"
                    id="emailFrom"
                    name="email_from"
                    value={emailFrom}
                />
                <button className='buttonFormLogin' style={{ marginTop: "20px", marginLeft:"0px" }}>Envoyer</button>
            </form>  
      </article>


        
      </>
 
  )

}

export default ForgotPassword