import React from "react";
import { Quill } from "react-quill";
import TableModule from 'quill1-table';
import './quill.css'

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = ["Default","arial","roboto","georgia","helvetica","lucida"];
Quill.register(Font, true);


Quill.register('modules/table', TableModule);

const defaultToolbar = [

  [{ table: TableModule.tableOptions()},{
    table: [
      'insert',
      'append-row-above',
      'append-row-below',
      'append-col-before',
      'append-col-after',
      'remove-col',
      'remove-row',
      'remove-table',
      'merge-selection',
      'remove-cell',
      'remove-selection',
      'hide-border',
      'show-border',
      'undo',
      'redo'
    ]
  }],
  [{'size': Size.whitelist}],
  [{ 'font': Font.whitelist }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block', 'image'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['clean']
];

// Modules object for setting up the Quill editor
export const modules = {

  toolbar: defaultToolbar,

  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },

  table: true,
};
 
// Quill Toolbar component
export const QuillToolbar = () => (

  <>
  
  </>

);

export default QuillToolbar;
