import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../../api/axios';
import { Link } from "react-router-dom";
import '../css/register.css';

const USER_REGEX = /^[a-zA-Z0-9._-]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const LICENCE_REGEX = /^[0-9]{8}$/;
const MAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\.[a-z]{2,4}$/;
const REGISTER_URL = '/tables/addrecord/user';
const VERIFYLICENCE_URL = '/tables/verifylicence';
const VERIFYUSER_URL = '/tables/verifyuser';
const MODIFY_LICENCE = '/tables/modrecord/licence';


const Register = () => {

    const mailRef = useRef();
    const licenceRef = useRef();
    const userRef = useRef();
    const errRef = useRef();
    const sucRef = useRef();
    const nomRef = useRef();
    const prenomRef = useRef();

    const [nom, setNom] = useState('');
    const [nomFocus, setNomFocus] = useState(false);

    const [prenom, setPrenom] = useState('');
    const [prenomFocus, setPrenomFocus] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [mail, setMail] = useState('');
    const [validMail, setValidMail] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);

    const [licence, setLicence] = useState('');
    const [validLicence, setValidLicence] = useState(false);
    const [licenceFocus, setLicenceFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [sucMsg, setSucMsg] = useState('');

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {    
            setSucMsg('');
            setErrMsg('');
          }, 1000);
          return () => { clearTimeout(timeout) }
    }, [errMsg , sucMsg])
    
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    useEffect(() => {
        setSucMsg('');
    }, [])

    useEffect(() => {
        setValidMail(MAIL_REGEX.test(mail));
    }, [mail])

    useEffect(() => {
        setValidLicence(LICENCE_REGEX.test(licence));
    }, [licence])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }

        

        
    var licenceid = '';
    var body = {"name" : user,"password" : pwd, "licence" : licence, "mail" : mail}
    axios
        .post(VERIFYLICENCE_URL,body)
        .then((res) => {
            if (!res.data.rows[0].used)
            {
                licenceid = res.data.rows[0].id
                axios
                .post(VERIFYUSER_URL,body)
                .then((res) => {
                    if (res.data.rowCount !== 1)
                    {
                        body = {"name" : user,"password" : pwd, "licence" : licenceid , "mail" : mail , "verif" : licence , "nom" : nom , "prenom" : prenom}       
                        axios
                        .post(REGISTER_URL,body)
                        .then((res) => {
                            body = {"id" : licenceid}  
                            axios
                            .post(MODIFY_LICENCE,body)
                            .then((res) => {
                                setSucMsg('Compte créé avec succès')
                            })
                        })
                    }
                    else
                    {
                        setErrMsg('Identifiant déja existant')
                    }
                })
            }         
        })
        .catch(error => {
            setErrMsg('Numéro de Licence non valide')
        })
    }


    return (
        <>
        {success ? (
            <section>
                <h1>Success!</h1>
                <p>
                    <a href="#">Sign In</a>
                </p>
            </section>
        ) : (
        <section className="register">

        <p ref={sucRef} className={sucMsg ? "sucmsg" : "offscreen"} aria-live="assertive">{sucMsg}</p>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

        <div className="vertical-center">

            
            <h1>Enregistrement</h1>
            <br />
            <img className="stop-logo"/> 
            <br />  

            <form onSubmit={handleSubmit}>

                <label htmlFor="username">
                    Identifiant:
                    <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                    aria-invalid={validName ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                />
                <br/>
                <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    4 à 24 caractères.<br />
                    Doit commencer par une lettre<br />
                    Lettres, chiffres, underscores, autorisés.
                </p>

                <br/>
                <label htmlFor="password">
                    Mot de passe:
                    <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                />
                <br/>
                <label htmlFor="confirm_pwd">
                    Confirmation du mot de passe:
                    <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="password"
                    id="confirm_pwd"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                />
                <br/>
                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    8 to 24 caratères.<br />
                    Doit inclure lettres majuscules et minuscules, chiffre(s) et un caractère spécial.<br />
                    Caractères spéciaux autorisés: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>       
                </p>
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    les mots de passe doivent correspondre.
                </p>



                <br/>


                <label htmlFor="nom">
                    Nom:
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="nom"
                    ref={nomRef}
                    autoComplete="off"
                    onChange={(e) => setNom(e.target.value)}
                    value={nom}
                    required
                    onFocus={() => setNomFocus(true)}
                    onBlur={() => setNomFocus(false)}
                />
                <br/>
       
                <label htmlFor="prenom">
                    Prénom:
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="prenom"
                    ref={prenomRef}
                    autoComplete="off"
                    onChange={(e) => setPrenom(e.target.value)}
                    value={prenom}
                    required
                    onFocus={() => setPrenomFocus(true)}
                    onBlur={() => setPrenomFocus(false)}
                />
                <br/>



                <label htmlFor="mail">
                    Adresse mail:
                    <FontAwesomeIcon icon={faCheck} className={validMail ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validMail || !mail ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="mail"
                    ref={mailRef}
                    autoComplete="off"
                    onChange={(e) => setMail(e.target.value)}
                    value={mail}
                    required
                    aria-invalid={validMail ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setMailFocus(true)}
                    onBlur={() => setMailFocus(false)}
                />
                <br/>
                <p id="uidnote" className={mailFocus && mail && !validMail? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    Doit etre au bon format d'adresse mail <br />
                    ex : jeandupont@google.fr
                </p>
                <br/>
                <label htmlFor="licence">
                    Numéro de licence :
                    <FontAwesomeIcon icon={faCheck} className={validLicence ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validLicence || !licence ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="licence"
                    ref={licenceRef}
                    autoComplete="off"
                    onChange={(e) => setLicence(e.target.value)}
                    value={licence}
                    required
                    aria-invalid={validLicence ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setLicenceFocus(true)}
                    onBlur={() => setLicenceFocus(false)}
                />
                <br/>
                <p id="uidnote" className={licenceFocus && licence && !validLicence? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    Doit etre au bon format de licence FFTir <br />
                    ex : 12345678
                </p>
                <button className='buttonFormLogin' disabled={!validName || !validPwd || !validMatch || !validMail  || !validLicence ? true : false}>Enregistrement</button>
            </form>
            <br/>
            <p>
                Déjà enregistré ?<br />
                <span className="line">
                    <Link to="/">Connection</Link>
                </span>
            </p>
        </div>
        </section>
        )}
        </>
    )
}

export default Register