import { Outlet } from "react-router-dom"

import '../css/layout.css';

import { TextInfo } from '../TextInfo/TextInfo'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const Informations = () => {

    return (

        <main className="App">

    <Container className="fill margin10">

      <Row>
        <Col>

            <TextInfo />

        </Col>
      </Row>

    </Container>

    
        
            
    <Outlet />
         
        </main>
    )
}

export default Informations