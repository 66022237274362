// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userForm
{
    position: relative;
    max-width: 50%;
}

.infoInputProfil
{
    border-radius: 10px;
    height: 40px;
    padding: 10px;
    width: 90%;
}

.infoInputProfilAvatar
{
    border-radius: 10px;
    height: 112px;
    padding: 10px;
    width: 90%;
}

.infoInputProfilButton
{
    height: 31px;
    padding: 0px;
    width: 90%;
    border-radius: 10px;
}

.infoInputBR
{
    height: 10px;
}

.buttonFormLoginProfil
{
    text-align: center;
    margin: 20px;
    width: 90%;
    border-radius: 10px;
    background-color: blue;
    color: white;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Users/Users.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;;IAEI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,UAAU;AACd;;AAEA;;IAEI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,UAAU;AACd;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".userForm\r\n{\r\n    position: relative;\r\n    max-width: 50%;\r\n}\r\n\r\n.infoInputProfil\r\n{\r\n    border-radius: 10px;\r\n    height: 40px;\r\n    padding: 10px;\r\n    width: 90%;\r\n}\r\n\r\n.infoInputProfilAvatar\r\n{\r\n    border-radius: 10px;\r\n    height: 112px;\r\n    padding: 10px;\r\n    width: 90%;\r\n}\r\n\r\n.infoInputProfilButton\r\n{\r\n    height: 31px;\r\n    padding: 0px;\r\n    width: 90%;\r\n    border-radius: 10px;\r\n}\r\n\r\n.infoInputBR\r\n{\r\n    height: 10px;\r\n}\r\n\r\n.buttonFormLoginProfil\r\n{\r\n    text-align: center;\r\n    margin: 20px;\r\n    width: 90%;\r\n    border-radius: 10px;\r\n    background-color: blue;\r\n    color: white;\r\n    height: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
