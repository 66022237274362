import React from "react";
import axios from '../../api/axios';
import { useRef, useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import './annonces.css'
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import nophoto from '../../media/images/nophoto.png'

const GETANNONCES_URL = '/tables/getrecord/annonces';
const GETPHOTOSANNONCES_URL = '/tables/getrecord/photosannonces';

export const LesAnnonces = () => {

    const [annonces, setAnnonces] = useState();
    const [photos, setPhotos] = useState(new Map());
    const [armedepoing, setArmedepoing] = useState([]);
    const [armelongue, setArmelongue] = useState([]);
    const [armeancienne, setArmeancienne] = useState([]);
    const [vetements, setVetement] = useState([]);
    const [accessoires, setAccessoires] = useState([]);
    const [munitions, setMunitions] = useState([]);
    const [outils, setOutils] = useState([]);

    useEffect(() => {    
        axios
          .post(GETANNONCES_URL)
          .then((res) => {

            res.data.rows?.map((annonce) => {
              
              if (annonce.typearme === 1)
              {
                setArmelongue((armelongue) => [...armelongue , annonce ])
              }
              else if (annonce.typearme === 2)
              {
                setArmedepoing((armedepoing) => [...armedepoing , annonce ])
              }
              else if (annonce.typearme === 3)
              {
                setArmeancienne((armeancienne) => [...armeancienne , annonce ])
              }
              else if (annonce.typearme === 4)
              {
                setVetement((vetements) => [...vetements , annonce ])
              }
              else if (annonce.typearme === 5)
              {
                setAccessoires((accessoires) => [...accessoires , annonce ])
              }
              else if (annonce.typearme === 6)
              {
                setMunitions((munitions) => [...munitions , annonce ])
              }
              else if (annonce.typearme === 7)
              {
                setOutils((outils) => [...outils , annonce ])
              }

              axios
                .post(GETPHOTOSANNONCES_URL,{"id" : annonce.annonceid })
                .then((res) => {
                  setPhotos(map => new Map(map.set(annonce.annonceid, res.data.rows)));
                })
            })

            setAnnonces((res.data.rows))
          })
    },[])


    return (
      <>
      <Container className='cardannonceContainer'>
            
      <Row xs={1} className="g-4 row2 ">
      <Accordion>
      <div className="cardannonce">


    

      {(armelongue.length === 0)? (
        <Accordion.Item eventKey="ArmeLongue" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armelongue.length} ) Armes Longues </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="ArmeLongue" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armelongue.length} ) Armes Longues </Accordion.Header>
        {armelongue?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      {(armedepoing.length === 0)? (
        <Accordion.Item eventKey="ArmedePoing" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armedepoing.length} ) Armes de Poing </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="ArmedePoing" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armedepoing.length} ) Armes de Poing </Accordion.Header>
        {armedepoing?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}
        



      {(armeancienne.length === 0)? (
        <Accordion.Item eventKey="ArmeAncienne" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armeancienne.length} ) Armes Anciennes </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="ArmeAncienne" className="accordionitemannoncegeneral">
        <Accordion.Header>( {armeancienne.length} ) Armes Anciennes </Accordion.Header>
        {armeancienne?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      {(vetements.length === 0)? (
        <Accordion.Item eventKey="vetements" className="accordionitemannoncegeneral">
        <Accordion.Header>( {vetements.length} ) Vetements </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="vetements" className="accordionitemannoncegeneral">
        <Accordion.Header>( {vetements.length} ) Vetements </Accordion.Header>
        {vetements?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      {(accessoires.length === 0)? (
        <Accordion.Item eventKey="accessoires" className="accordionitemannoncegeneral">
        <Accordion.Header>( {accessoires.length} ) Accessoires </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="accessoires" className="accordionitemannoncegeneral">
        <Accordion.Header>( {accessoires.length} ) Accessoires </Accordion.Header>
        {accessoires?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      {(munitions.length === 0)? (
        <Accordion.Item eventKey="munitions" className="accordionitemannoncegeneral">
        <Accordion.Header>( {munitions.length} ) Munitions </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="munitions" className="accordionitemannoncegeneral">
        <Accordion.Header>( {munitions.length} ) Munitions </Accordion.Header>
        {munitions?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      {(outils.length === 0)? (
        <Accordion.Item eventKey="outils" className="accordionitemannoncegeneral">
        <Accordion.Header>( {outils.length} ) Outils </Accordion.Header>
        </Accordion.Item>
      ):(  
        <div>
        <Accordion.Item eventKey="outils" className="accordionitemannoncegeneral">
        <Accordion.Header>( {outils.length} ) Outils </Accordion.Header>
        {outils?.map((annonce,index) => {
          return (
            <Accordion.Body className='accordionannoncegeneral'>
                <Card className='cardannoncegeneral'>
                <Card.Header className='cardannonceheader'>{annonce.titre}</Card.Header>
                <Card.Subtitle className='cardannoncesubtitle'>{annonce.type}</Card.Subtitle>   
                <Card.Body className='cardannoncebody'>
                  <Carousel fade className="cardannoncecarroussel">
                  {((photos.get(annonce.annonceid))?.length !== 0)? 
                  (
                    photos.get(annonce.annonceid)?.map((photo) => {
                    return (                   
                      <Carousel.Item>
                        <img className="cardannonceimgfit" src={photo.photo}/>
                      </Carousel.Item>
                    )
                    })              
                  ):(                     
                    <Carousel.Item>
                      <img src={nophoto} className="nophoto"></img>   
                    </Carousel.Item>
                  )}
                  </Carousel> 
                  <div className="cardannoncetext">
                  {annonce.contenu}
                  </div>
                </Card.Body>
                <Card.Footer className='cardannoncefooter'>Prix : {annonce.prix} €  -  Contact : {annonce.contact}</Card.Footer>    
            </Card>
            </Accordion.Body>         
          )
        }
        )}
        </Accordion.Item>
        </div>
      )}




      </div>
      </Accordion>
      </Row>
      
      </Container>
      </>
    )
}