import React from 'react';
import './App.css';
import Login from './components/Pages/Login';
import Register from './components/Pages/Register';
import Forgotpassword from './components/Pages/Forgotpassword';
import Navbar from './components/NavBar/Navbar';
import {Route, Routes } from 'react-router-dom';
import Layout from './components/Pages/layout';
import RequireAuth from './components/Pages/RequireAuth';
import Calendrier from './components/Pages/calendrier';
import Profil from './components/Users/Users';
import Informations from './components/Pages/informations';
import Unauthorized from './components/Pages/Unauthorizes';
import Missing from './components/Pages/Missing';
import Administration from './components/Pages/administration';
import Permanence from './components/Pages/permanence';
import Ventes from './components/Pages/ventes';
import Examens from './components/Pages/examens';
import socketIO from 'socket.io-client';
import { useRef, useState, useEffect } from 'react';
import useAuth from './hooks/useAuth';
import axios from './api/axios';
import { useDispatch } from 'react-redux'
import { addUser } from './reducers/UserReducer';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCookies  } from 'react-cookie';

const socket = socketIO.connect(process.env.REACT_APP_URL2);

const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Controleur' : 4832,
  'Admin': 5150
}

const GETUSERBYID = '/tables/getrecord/userid';

function App() {

  const dispatch = useDispatch()
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  
  /*
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");

    if (loggedInUser !== "undefined" && loggedInUser) {
      axios
        .post(GETUSERBYID,{"id" : loggedInUser} )
        .then((res) => {
          
            const accessToken = res?.data.rows[0].licence;
            let user = res?.data.rows[0].name;
            let role = [res?.data.rows[0].value];
            let nom = res?.data.rows[0].nom;
            let prenom = res?.data.rows[0].prenom;
            let avatar = res?.data.rows[0].avatar;
            let userid = res?.data.rows[0].userid;
            let pwd = res?.data.rows[0].password;
            let rol = res?.data.rows[0].role;
            const roles = role;
            setAuth({ user, pwd, roles, accessToken });
            dispatch(addUser({user,accessToken,nom,prenom,avatar,userid,rol}));
            navigate(from, { replace: true });
      })
    }
  }, []);
*/
  
  return (
    <>

        <Navbar />

        <Routes>

          <Route path='/' element={<Layout />} />

          {/* public routes */}

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotpassword" element={<Forgotpassword />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="informations" element={<Informations />} />

          {/* we want to protect these routes */}

          
          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin , ROLES.Editor , ROLES.Controleur ]} />}>
            <Route path="profil" element={<Profil/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin , ROLES.Editor , ROLES.Controleur  ]} />}>
            <Route path="calendrier" element={<Calendrier socket={socket}/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin , ROLES.Editor , ROLES.Controleur ]} />}>
            <Route path="ventes" element={<Ventes socket={socket}/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Editor , ROLES.Admin , ROLES.Controleur  ]} />}>
            <Route path="permanence" element={<Permanence socket={socket}/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Controleur , ROLES.Admin]} />}>
            <Route path="examens" element={<Examens socket={socket}/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="administration" element={<Administration />} />
          </Route>

          
          

          {/* catch all */}
          <Route path="*" element={<Missing />} />

        </Routes>

    </>
  );
}

export default App;
