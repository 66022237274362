import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name : null,
  licence : null,
  nom : null,
  prenom : null,
  avatar : null,
  userid : null,
  role : null,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,

  reducers: {

    addUser: (state, action) => {
      state.name = action.payload.user
      state.licence = action.payload.accessToken
      state.nom = action.payload.nom
      state.prenom = action.payload.prenom
      state.avatar = action.payload.avatar
      state.userid = action.payload.userid
      state.role = action.payload.role
    },

  },
});

export const { addUser } = userSlice.actions;

export default userSlice.reducer;