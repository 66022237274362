// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc .fc-col-header-cell-cushion 
{
    text-decoration: none;
}

.fc-daygrid-day-number
{
    text-decoration: none;
    font-size: x-small;
}

.fc-daygrid-month-start
{
    font-size: 0.7em !important;
}

.calendarTir .fc-daygrid-dot-event .fc-event-title {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: 700;
    min-width: 0px;
    overflow: hidden;
    position: relative !important;
    top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TirPlateau/TirPlateau.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;AACzB;;AAEA;;IAEI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;IAEI,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".fc .fc-col-header-cell-cushion \r\n{\r\n    text-decoration: none;\r\n}\r\n\r\n.fc-daygrid-day-number\r\n{\r\n    text-decoration: none;\r\n    font-size: x-small;\r\n}\r\n\r\n.fc-daygrid-month-start\r\n{\r\n    font-size: 0.7em !important;\r\n}\r\n\r\n.calendarTir .fc-daygrid-dot-event .fc-event-title {\r\n    flex-grow: 1;\r\n    flex-shrink: 1;\r\n    font-weight: 700;\r\n    min-width: 0px;\r\n    overflow: hidden;\r\n    position: relative !important;\r\n    top: 0px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
