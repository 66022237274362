import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import axios from '../../api/axios';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import Resizer from "react-image-file-resizer";
import './MediaGestion.css'

const ADDCARROUSSEL = '/tables/addrecord/carroussel'
const DELCARROUSSEL = '/tables/delrecord/carroussel'
const GETCARROUSSEL = '/tables/getrecord/carroussel'
const GETALLCARROUSSEL = '/tables/getAllrecord/carroussel'


export const MediaGestion = () => {

    const [images, setImages] = React.useState([]);

    useEffect(() => {
        
        axios
          .post(GETALLCARROUSSEL)
          .then((res) => {
            setImages(res.data.rows)
          })
    })
    
    const imageUpload = async (e) => {
        const file = e.target.files[0];
        const image = await resizeFile(file);
        var body = { "image" : image}
        await axios.post(ADDCARROUSSEL,body); 
    };

    const imageDelete = async (id) => {   
        var body = {"id" : id}
        await axios.post(DELCARROUSSEL,body);
    };

    const resizeFile = (file) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(file,600,600,"JPEG",100,0,(uri) => {
            resolve(uri);
        },
        "base64"
        );
    });


    return (
        
        <>
        
            <h4 className="primary-header Banniere">               
                Gestionnaire des Images
            </h4>
            <br/>

            <Container className='Container'>
      
            
                <input
                    className='imagebutton'
                    type="file" 
                    id="imageFile" 
                    name='imageFile' 
                    onChange={imageUpload} 
                />
            
            </Container>
           
            <Container className='ContainerImages'>
            
                <Row xs={1} md={3} className="g-4 row2">
                {images.map((image) => {
                return (
                  
                    <Card className='card2'>
                        <Card.Img variant="top" src={image.images} />
                        <Card.Body className="cardBody2">
                        <Button className='btn2' variant="danger" onClick={() => imageDelete(image.carrousselid)}>Delete Image</Button>
                        </Card.Body>
                    </Card>
                   
                )
                })}
            </Row>
            
            </Container>
        
            </>
    )
}