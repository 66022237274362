import '../css/layout.css';
import Permanences  from '../Permanences/Permanences'
import { useSelector } from 'react-redux';

const Permanence = ({socket}) => {

    const user = useSelector((state) => state.users);

    return (

        <Permanences username={user} socket={socket}/>

    )
}

export default Permanence