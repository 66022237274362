import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../api/axios';
import { useState , useEffect} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EditorToolbar, { modules } from "./EditorToolbar";

const ADDTEXTACCEUIL_URL = '/tables/addrecord/textacceuil';
const GETTEXTACCEUIL_URL = '/tables/getrecord/textacceuil';
const MODTEXTACCEUIL_URL = '/tables/modrecord/textacceuil';

const ADDTEXTINFO_URL = '/tables/addrecord/textinfo';
const GETTEXTINFO_URL = '/tables/getrecord/textinfo';
const MODTEXTINFO_URL = '/tables/modrecord/textinfo';

export const Quill = () => {
    
    const [valuePage, setValuePage] = useState('1');
    const [valueAcceuil, setValueAcceuil] = React.useState('');
    const [valueInfo, setValueInfo] = React.useState('');

    const handleSubmitAcceuil = async (e) => {
        e.preventDefault();
        const response = await axios.post(GETTEXTACCEUIL_URL,body);
        if (response.data.rowCount > 0)
        {
            var body = { "value" : valueAcceuil}
            const response2 = await axios.post(MODTEXTACCEUIL_URL,body);
        }
        else
        {
            var body = { "value" : valueAcceuil}
            const response3 = await axios.post(ADDTEXTACCEUIL_URL,body);
        }
        
    }

    const handleSubmitInfo = async (e) => {
        e.preventDefault();
        const response = await axios.post(GETTEXTINFO_URL,body);
        if (response.data.rowCount > 0)
        {
            var body = { "value" : valueInfo}
            const response2 = await axios.post(MODTEXTINFO_URL,body);
        }
        else
        {
            var body = { "value" : valueInfo}
            const response3 = await axios.post(ADDTEXTINFO_URL,body);
        }
        
    }

    const handleChange = async(event, newValue) => {
        event.preventDefault();
        setValuePage(newValue); 
        if (newValue == 1)
        {
            getAcceuil();
        }
        else if (newValue == 2)
        {
            getInfo();
        }
        
    };

    const getAcceuil = async() => {
        
        axios
          .post(GETTEXTACCEUIL_URL)
          .then((res) => {
            setValueAcceuil(res.data.rows[0].textacceuil)
          })
    }

    const getInfo = async() => {
        
        axios
          .post(GETTEXTINFO_URL)
          .then((res) => {
            setValueInfo(res.data.rows[0].textinfo)
          })
    }

    useEffect(() => {
        setValuePage('1')
        getAcceuil()
    }, []);

    return (

        <section>

        <Box sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={valuePage}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Acceuil" value="1" />
            <Tab label="Informations" value="2" />

            </TabList>
        </Box>


        <TabPanel value="1">
            
            <form onSubmit={handleSubmitAcceuil}>

            <EditorToolbar />
            <ReactQuill modules={modules} theme="snow" value={valueAcceuil} onChange={setValueAcceuil} />

            <button className='buttonFormLogin' disabled={!valueAcceuil  ? true : false}>Enregistrement</button>

            </form>

        </TabPanel>


        <TabPanel value="2">

            <form onSubmit={handleSubmitInfo}>

            <EditorToolbar />
            <ReactQuill modules={modules} theme="snow" value={valueInfo} onChange={setValueInfo} />

            <button className='buttonFormLogin' disabled={!valueInfo  ? true : false}>Enregistrement</button>

            </form>
       
        </TabPanel>

        </TabContext>

        </Box>

        </section>

        
    )
}
