import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import REACT_APP_ICON_URL from '../../media/images/LOGO STAR QUADRI.small.png';

import './Meteo.css'

const REACT_APP_API_URL = 'https://api.openweathermap.org/data/2.5';
const REACT_APP_API_KEY = 'd84228b815e991425cbdf32af4db1b44';

library.add(fas)

export const Meteo = () => {
    
    

    const lat = 46.2209;
    const long = 3.1650;

    const [data, setData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            await fetch(`${REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${REACT_APP_API_KEY}`)
            .then(res => res.json())
            .then(result => {
                setData(result)
            });

        }

        fetchData();

    },[])

    return (


        
            
        <div className="meteoCard">
            

        {(typeof data.main != 'undefined') ? (
                 

        <Card style={{ width: '100%' }} className='card1'>
        
        {/*         
        
        <Card.Img variant="top" src={REACT_APP_ICON_URL} className="logoMeteo"/>
        
        */}
        
        <Card.Body>
        <Card.Text className="meteoText">

            <Container>
                <Row className="meteoMenu">
                    Actuellement sur le terrain de tir :
                </Row>

                <Row>

                    <Col className="colIcon">   
                        <FontAwesomeIcon className="meteoIcon" icon="fa-solid fa-temperature-three-quarters" />
                    </Col>
                    <Col className="colText">   
                        {data.main.temp} °C                                          
                    </Col>

                    <Col className="colIcon">
                        <FontAwesomeIcon className="meteoIcon"  icon="fa-solid fa-gauge" />  
                    </Col>
                    <Col className="colText">
                        {data.main.pressure} Pa    
                    </Col>
                </Row>
                <Row>

                    <Col className="colIcon">
                        <FontAwesomeIcon className="meteoIcon"  icon="fa-solid fa-cloud" />
                    </Col>
                    <Col className="colText">
                        {data.clouds.all} %     
                    </Col>

                    <Col className="colIcon">
                        <FontAwesomeIcon className="meteoIcon"  icon="fa-solid fa-wind" />
                    </Col>
                    <Col className="colText">
                        {data.wind.speed} km/h    
                    </Col>
                </Row>
                <Row>
                    <Col className="colIcon">
                        <FontAwesomeIcon className="meteoIcon"  icon="fa-solid fa-water" />
                    </Col>
                    <Col className="colText">
                        {data.main.humidity} %   
                    </Col>

                    <Col className="colIcon">
                        <FontAwesomeIcon className="meteoIcon"  icon="fa-solid fa-compass" />
                    </Col>
                    <Col className="colText">
                        {data.wind.deg} °    
                    </Col>
             
                   
                </Row>
            </Container>
            
         
        </Card.Text>
 
        </Card.Body>
        </Card>

        ): ( <div></div> )}

        </div>
        
    )

}