// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarTir
{
    margin-top: 20px;
}

.textresa 
{
    margin-right: 140px;
    font-size: 13px;
    position: fixed;
    left: 30px;
    width: 200px;
}

.externaleventsTirControl
{
    left: 100px;
}

.reservation
{
    left: 100px;
}

.fc-timeline-event
{
    display: flex;
    height: 30px; 
    border-radius: 10px;
    padding: 0px;
    margin: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/TirControle/TirControle.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,UAAU;IACV,YAAY;AAChB;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".calendarTir\r\n{\r\n    margin-top: 20px;\r\n}\r\n\r\n.textresa \r\n{\r\n    margin-right: 140px;\r\n    font-size: 13px;\r\n    position: fixed;\r\n    left: 30px;\r\n    width: 200px;\r\n}\r\n\r\n.externaleventsTirControl\r\n{\r\n    left: 100px;\r\n}\r\n\r\n.reservation\r\n{\r\n    left: 100px;\r\n}\r\n\r\n.fc-timeline-event\r\n{\r\n    display: flex;\r\n    height: 30px; \r\n    border-radius: 10px;\r\n    padding: 0px;\r\n    margin: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
