import '../css/layout.css';
import * as React from 'react';
import { TirControle } from '../Examens/TirControle';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Examens = ({socket}) => {

    const user = useSelector((state) => state.users);
    const [value, setValue] = React.useState('1');

    const handleChange = async(event, newValue) => {
        setValue(newValue);
    };

     return (




    <Box sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={value}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed' , backgroundColor : 'white' }}>
            <TabList onChange={handleChange} >
            <Tab label="Tir Contrôle" value="1" />
            </TabList>
        </Box>

        <TabPanel value="1" >
            <TirControle username={user} socket={socket}/>          
        </TabPanel>

        </TabContext>

    </Box>



    );
    
}

export default Examens