const Data = {
    resourceAreaColumns: [
      {
        field: 'title',
        headerContent: 'Controleur(s)'
      },
    ],
    resources: [

      {
        id: "r1",
        title: "Poste Tir 1",
      },
      {
        id: "r2",
        title: "Poste Tir 2",
      },
      {
        id: "r3",
        title: "Poste Tir 3",
      },
      {
        id: "r4",
        title: "Poste Tir 4",
      },
      {
        id: "r5",
        title: "Poste Tir 5",
      },
      {
        id: "r6",
        title: "Poste Tir 6",
      },
      {
        id: "r7",
        title: "Poste Tir 7",
      },
      
    ],
  };
  
  export default Data;
  