import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { MesAnnonces } from '../Annonces/MesAnnonces';
import { LesAnnonces } from '../Annonces/LesAnnonces';

const Ventes = ({socket}) => {

    const user = useSelector((state) => state.users);
    const [value, setValue] = React.useState('1');
 

    const handleChange = async(event, newValue) => {
        setValue(newValue);
    };

    return (

    <section className='administrations'>


    <Box sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={value}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed' , backgroundColor : 'white' }}>
            <TabList onChange={handleChange} >
            <Tab label="Petites Annonces" value="1" />
            <Tab label="Mes Petites Annonces" value="2" />
            </TabList>
        </Box>


        <TabPanel value="1" >
            <LesAnnonces username={user} socket={socket}/>          
        </TabPanel>

        <TabPanel value="2">
            <MesAnnonces username={user} socket={socket}/>
        </TabPanel>



        </TabContext>

    </Box>

    </section>

    );
    }

    export default Ventes