// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc-license-message 
{
    visibility: hidden;
}

.fc 
{
    height: 70vh;
}

.externaleventsTirEcole
{
    left: 100px;
}

.reservationecole
{
    left: 100px;
}

.fc .fc-non-business 
{
    background: white;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/TirEcole/TirEcole.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".fc-license-message \r\n{\r\n    visibility: hidden;\r\n}\r\n\r\n.fc \r\n{\r\n    height: 70vh;\r\n}\r\n\r\n.externaleventsTirEcole\r\n{\r\n    left: 100px;\r\n}\r\n\r\n.reservationecole\r\n{\r\n    left: 100px;\r\n}\r\n\r\n.fc .fc-non-business \r\n{\r\n    background: white;\r\n    opacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
