import { useState, useEffect, createRef , useRef, memo} from 'react';
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid' 
import './Permanences.css'
import frLocale from '@fullcalendar/core/locales/fr';
import axios from 'axios';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';

const REGISTERPERMANENCE_URL = '/tables/addrecord/permanence';
const DEREGISTERPERMANENCE_URL = '/tables/delrecord/permanence';
const GETPERMANENCE_URL = '/tables/getrecord/permanence';

let newArr = [];
let mapObj = new Map();

const computeDurations = (mode) => {
    return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{weekday: 'short' , day: '2-digit' }],
        duration: { days: 7 },
    };
};

const ExternalEvent = memo(({ event }) => {
    let elRef = useRef(null);
  
    useEffect(() => {
      let draggable = new Draggable(elRef.current, {
        eventData: function () {
          return { ...event, create: true };
        }
      });
  
      // a cleanup function
      return () => draggable.destroy();
    });
  
    return (
      <div
        ref={elRef}
        className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
        title={event.title}
        style={{
          backgroundColor: event.color,
          borderColor: event.color,
          cursor: "pointer"
        }}
      >
        <div className="fc-event-main">
          <div>
            <strong>{event.title}</strong>
          </div>
        </div>
      </div>
    );
  });

const Permanences = ({username , socket}) => {
 
    const affichage = username.nom;
    moment.locale('FR-fr');
    const year = moment(new Date()).format("YYYY")
    const initialized = useRef(false);
    const [date] = useState(new Date());
    const { duration, slotDuration, slotLabelFormat } = computeDurations();
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedInfoDay, setSelectedInfoDay] = useState({heureDebut: "", heureFin: "", jourFin: "" , jourDebut: "" , modaltitle: "" , title: "" , start:"", end: "" , color: "" , rolesclub: ""});
    
    
    const [state] = useState({
        externalEvents: [
            { title: "Pas de Tir", color: "#FF2D2D", id: Math.floor(Math.random()*50000)},
            { title: "Ecole de Tir", color: "#33ACFF", id: Math.floor(Math.random()*50000) },
            { title: "Accueil", color: "#00E600", id: Math.floor(Math.random()*50000) },
            username.role === 'CONTROLEUR' || 'ADMIN' ? { title: "Control de Tir", color: "#FFA500", id: Math.floor(Math.random()*50000) }:{},
            { title: "Evenements", color: "#962DFF", id: Math.floor(Math.random()*50000) },
          ]});


    useEffect(() => {
        socket.on('messageResponse', (data) => {
            
            if ( data.text === 'addPermanence' )
            {
                setEvents((events) => [...events , data.event]);
                let clef = data.event.start + data.event.rolesclub
                if (!mapObj.has(clef)) {
                    mapObj.set(clef, []);
                }
                mapObj.get(clef).push(data.event);
      
            }
            else if ( data.text === 'delPermanence' )
            {
                if (newArr.findIndex(item => item.id === parseInt(data.event.id)) !== -1)
                {
                    newArr.splice(newArr.findIndex(item => item.id === parseInt(data.event.id)), 1)
                    setEvents(newArr);
                }   
                
                if (mapObj.get(data.event.key).findIndex(item => item.id === parseInt(data.event.id)) !== -1)
                {
                    (mapObj.get(data.event.key)).splice(mapObj.get(data.event.key).findIndex(item => item.id === parseInt(data.event.id)), 1)
                } 
            }        
            
        });
  
    }, []);

    useEffect(() => {
        newArr = [...events];
    }, [events]);

    useEffect(() => {
        
        if (!initialized.current) {
            initialized.current = true    
            setEvents([]);
            loadJoursFeries();     
            loadEvents();
        }
    }, [initialized.current]);


    const loadJoursFeries = async () => {

        axios
          .get('https://calendrier.api.gouv.fr/jours-feries/metropole/'+year+'.json')
          .then((res) => {
            Object.entries(res.data).forEach(([key, value]) => {
                setEvents((events) => [...events , 
                {
                    id:Math.floor(Math.random()*500000),
                    title:value,
                    start:key,
                    color:'#FF000033',
                    display: 'background'
                }])        
            });
        })
    }

    const loadEvents= async () => {
 
        axios
          .post(GETPERMANENCE_URL)
          .then((res) => {
            Object.entries(res.data.rows).forEach(([key, value]) => {
                if (value.rolesclub === "EVE")
                {
                    value.title = value.titreevenement
                }
                else
                {
                    value.title = value.nom +'\t\t\t'+ value.heuredebut.split(":")[0] + "h" +value.heuredebut.split(":")[1] + " à " + value.heurefin.split(":")[0] + "h" +value.heurefin.split(":")[1];
                    let clef = value.start + value.rolesclub
                    if (!mapObj.has(clef)) {
                        mapObj.set(clef, []);
                    }
                    mapObj.get(clef).push(value);
                }
                
                value.id = value.permanenceid;
                setEvents((events) => [...events , value]
            )})
        })
    }

    const handleRemoveItem = (e) => {
        const newArr = [...events];
        newArr.splice(newArr.findIndex(item => item.id === parseInt(e.id)), 1)
        var body = {"id" : e.id , "key" : e.startStr+e.extendedProps.rolesclub , "value" : e};
        deregisterEvent(body)
        return newArr
    };

    const handleEventReceive = (eventInfo) => {
        var hd = "";
        var hf = "";
        var pres = "";

        if (eventInfo.event.title === "Pas de Tir")
        {
            pres = "PDT"
            if (eventInfo.event.start.getDay() === 6 )
            {
                hd = "14:00:00"
                hf = "17:00:00"
            }
            else
            {
                hd = "09:00:00"
                hf = "12:00:00"
            }
        }
        else if (eventInfo.event.title === "Ecole de Tir")
        {
            pres = "EDT"
            if (eventInfo.event.start.getDay() === 6 )
            {
                hd = "14:30:00"
                hf = "16:30:00"
            }
            else
            {
                hd = "09:30:00"
                hf = "11:30:00"
            }
        }
        else if (eventInfo.event.title === "Accueil")
        {
            pres = "ACC"
            if (eventInfo.event.start.getDay() === 6 )
            {
                hd = "14:00:00"
                hf = "17:00:00"
            }
            else
            {
                hd = "09:00:00"
                hf = "12:00:00"
            }
        }
        else if (eventInfo.event.title === "Control de Tir")
        {
            pres = "CDT"
            if (eventInfo.event.start.getDay() === 6 )
            {
                hd = "14:30:00"
                hf = "16:30:00"
            }
            else
            {
                hd = "09:30:00"
                hf = "11:30:00"
            }
        }
        else if (eventInfo.event.title === "Evenements")
        {
            pres = "EVE"
            hd = "09:00:00"
            hf = "17:00:00"
        }

        const eventToState = {
            modaltitle : eventInfo.event.title,
            title: affichage,
            color: eventInfo.event.backgroundColor,
            start: eventInfo.event.startStr,
            end : eventInfo.event.startStr,
            rolesclub : pres,
            jourDebut : 'jour'+ eventInfo.event.start.getDay() + 'Start',
            jourFin : 'jour'+ eventInfo.event.start.getDay() + 'End',
            heureDebut : hd,
            heureFin : hf,
        }
        setSelectedInfoDay(eventToState)
        setShowModal(true);
        
    };

    const saveEvent = () => {
        var titreevenement = ""
        var titlename =""
        var visible = false

        if (selectedInfoDay.rolesclub === 'EVE')
        {
            titreevenement=selectedInfoDay.title
        }
        else
        {
            titlename=affichage
        }

        if (selectedInfoDay.visible === true)
        {
            visible=true
        }
    
        const newEvent = {
            id: Math.floor(Math.random()*50000),
            title: titlename,
            color: selectedInfoDay.color,
            start: selectedInfoDay.start,
            rolesclub : selectedInfoDay.rolesclub,
            heureDebut : selectedInfoDay.heureDebut,
            heureFin : selectedInfoDay.heureFin,
            visible : visible,
            titreevenement : titreevenement
        }; 
        //setEvents((events) => [...events , newEvent]);
        var body = {"id" : newEvent.id, "start" : newEvent.start, "title" : username.userid, "color" : newEvent.color, "rolesclub" : newEvent.rolesclub , "heuredebut" : newEvent.heureDebut, "heurefin" : newEvent.heureFin , "visible" : newEvent.visible , "titreevenement" : newEvent.titreevenement};
        registerEvent(body) 
        setShowModal(false);
    }

    const eventClick = ({event}) => {
        if (event.title.split("\t\t\t")[0].trim() === affichage.trim())
        {      
            setEvents(handleRemoveItem(event))    
        }  
        else if (event.extendedProps.rolesclub === 'EVE' && username.role == 'ADMIN')
        {
            setEvents(handleRemoveItem(event))     
        }
    }

    const handlerEventCheckChange = (event) => {
        selectedInfoDay.visible = event.target.checked
    }

    const handlerEventNameChange = (event) => {
        selectedInfoDay.title = event.target.value
    }

    const handlerTimeStartChange = (event) => {
        selectedInfoDay.heureDebut = event.target.value+":00"
    }

    const handlerTimeEndChange = (event) => {
        selectedInfoDay.heureFin = event.target.value+":00"
    }

    const registerEvent = async (body) => {  
        await axios.post(REGISTERPERMANENCE_URL,body);
        if (body.rolesclub === 'EVE')
        {
            body.title = body.titreevenement
        }
        else
        {
            body.title = affichage;
        }      
        socket.emit('message', { text: 'addPermanence' , event : body }); 
    }

    const deregisterEvent = async (body) => {    
        socket.emit('message', { text: 'delPermanence' , event : body }); 
        await axios.post(DEREGISTERPERMANENCE_URL,body);  
    }
    
    const overlapAllowed = (stillEvent,movingEvent) => {

        let search='';
        let type = movingEvent.title;       
        switch (type) {
            case "Ecole de Tir" : search = "EDT" ; break;
            case "Accueil" : search = "ACC"; break;
            case "Pas de Tir" : search = "PDT"; break;
            case "Control de Tir" : search = "CDT"; break;
            case "Evenements" : search = "EVE"; break;
        }

        if (stillEvent.extendedProps.rolesclub === search && search === 'ACC')
        {
            if (mapObj.get(stillEvent.startStr+stillEvent.extendedProps.rolesclub).length >= 2 )
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else if (stillEvent.extendedProps.rolesclub === search && search === 'EDT')
        {
            if (mapObj.get(stillEvent.startStr+stillEvent.extendedProps.rolesclub).length >= 2 )
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else if (stillEvent.extendedProps.rolesclub === search && search === 'CDT')
        {
            if (mapObj.get(stillEvent.startStr+stillEvent.extendedProps.rolesclub).length >= 3 )
            {
                return false;
            }
            else
            {
                return true;
            }
        }
        else if (stillEvent.extendedProps.rolesclub === search && search === 'PDT')
        {
            if (mapObj.get(stillEvent.startStr+stillEvent.extendedProps.rolesclub).length >= 3 )
            {
                return false;
            }
            else
            {
                return true;
            }
        }           
        return true;
    
    }


    return (
    
        <>
            <h4 className="primary-header Banniere">               
                Permanences
            </h4>
          
            <div id="external-events" className='externalevents'>
            {state.externalEvents.map(event => (
                event.id ? <ExternalEvent className="externalevent" key={event.id} event={event} /> : <></>
            ))}
            </div>

            <div className='calendarPermanence'>
                <FullCalendar    
                    selectable={true}
                    locale={frLocale}
                    displayEventEnd={true}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridWeek"

                    headerToolbar={{
                        left: 'prev next today',
                        center: 'title',
                        right: ''
                    }}
                    slotDuration={slotDuration}
                    slotLabelFormat={slotLabelFormat}
                    duration={duration}
                    hiddenDays={[2,3,4,5]}
                    events={events} 
                    dayMaxEvents={true}            
                    eventReceive={handleEventReceive}
                    updateSize={true}
                    eventClick= {eventClick}
                    eventOverlap={overlapAllowed}
                    now={date}
                    firstDay={date.getDate()}
                />
            </div>

            <Modal className=".modal-dialog-centered" show={showModal} aria-labelledby="modal-title">         
                <Modal.Body>          
                <div className='fullmodal'>
                    <div className='textmodal'>
                        Creation d'une permanence : 
                        {selectedInfoDay.modaltitle === 'Evenements' ? (
                            <>
                            <label for="appt" className='apptLabelEvenement'>Nom de l'evenement : </label>
                            <input onChange={handlerEventNameChange} class="form-control apptControl apptEvenement" />
                            <input type="checkbox" onChange={handlerEventCheckChange} id="scales"  name="scales"/>
                            <label for="scales" className='checkboxtext'>Visible sur la page d'accueil</label>
                            </>                   
                        ):(
                            <div className='textmodalType'>{selectedInfoDay.modaltitle}</div>
                        )}                                         
                    </div>
                    <br/>
                    <div className='choixhoraires'>                  
                        <label for="appt" className='apptLabel'>Debut : </label>
                        <input type="time" onChange={handlerTimeStartChange} defaultValue ={selectedInfoDay.heureDebut} class="form-control apptControl" list={selectedInfoDay.jourDebut} />                                          
                        <label for="appt" className='apptLabel'>Fin : </label>
                        <input type="time" onChange={handlerTimeEndChange} defaultValue ={selectedInfoDay.heureFin} class="form-control apptControl" list={selectedInfoDay.jourFin}/>     
                        <datalist id="jour6Start">
                            <option value="14:30:00"/>
                            <option value="15:30:00"/>                     
                        </datalist>
                        <datalist id="jour6End">
                            <option value="15:30:00"/>
                            <option value="16:30:00"/>                   
                        </datalist>

                        <datalist id="jour0Start">
                            <option value="09:30:00"/>
                            <option value="10:30:00"/>                   
                        </datalist>

                        <datalist id="jour0End">
                            <option value="10:30:00"/>
                            <option value="11:30:00"/>                   
                        </datalist>

                        <datalist id="jour1Start">
                            <option value="09:30:00"/>
                            <option value="10:30:00"/>                   
                        </datalist>

                        <datalist id="jour1End">
                            <option value="10:30:00"/>
                            <option value="11:30:00"/>                   
                        </datalist>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={saveEvent}>Valider</Button>
                </Modal.Footer>               
            </Modal>
        </>
    );

};


export default Permanences;