(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("quill"));
	else if(typeof define === 'function' && define.amd)
		define(["quill"], factory);
	else if(typeof exports === 'object')
		exports["quill1Table"] = factory(require("quill"));
	else
		root["quill1Table"] = factory(root["quill"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__665__) => {
return 