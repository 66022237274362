import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import axios from '../../api/axios';
import { addUser } from '../../reducers/UserReducer';
import '../css/login.css';
import { useCookies  } from 'react-cookie';

const LOGIN_URL = '/tables/getrecord/user';

const Login = () => {

    const dispatch = useDispatch()
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();
    const sucRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [sucMsg, setSucMsg] = useState('');

    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    useEffect(() => {
        setSucMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        var body = {"name" : user,"password" : pwd}

        try {

            axios
                .post(LOGIN_URL,body )
                .then((res) => {
                 
                    const accessToken = res?.data.rows[0].licence;
                    let roles = [res?.data.rows[0].value];
                    let nom = res?.data.rows[0].nom;
                    let prenom = res?.data.rows[0].prenom;
                    let avatar = res?.data.rows[0].avatar;
                    let userid = res?.data.rows[0].userid;
                    let role = res?.data.rows[0].role;

                    localStorage.setItem('user', userid)
                    setCookie('user', user);

                    setSucMsg('Bienvenue');
                    sucRef.current.focus();

                    setAuth({ user, pwd, roles, accessToken });
                    dispatch(addUser({user,accessToken,nom,prenom,avatar,userid,role}));
        
                    setUser('');
                    setPwd('');
                    navigate(from, { replace: true });
            })
            .catch(error => {
                setErrMsg('Erreur d\'identifiant ou de mot de passe');
            })
        } 
        catch (err) 
        {  
            setErrMsg('No Server Response');       
            errRef.current.focus();    
        }
    }

    return (

       

        <section className="login">

        <p ref={sucRef} className={sucMsg ? "sucmsg" : "offscreen"} aria-live="assertive">{sucMsg}</p>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

        <div className="vertical-center">
            

            <h1>Connection</h1>
            <br />
            <img className="stop-logo"/> 
            <br />  

            <form onSubmit={handleSubmit}>

                <label htmlFor="username">identifiant ou Licence :</label>
                <br/>
                <input
                    className='infoInput'
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                />
                <br/>
                <label htmlFor="password">Mot de passe :</label>
                <br/>
                <input
                    className='infoInput'
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                />
                <br/>
                <button className='buttonFormLogin'>Sign In</button>
            </form>   

            <p>
                Besoin d'un accès ?<br />
                <span className="line">
                    <Link to="/register">Enregistrement</Link>
                </span>
            </p>

            <p>
                Mot de passe oublié ?<br />
                <span className="line">
                    <Link to="/forgotpassword">récupperer mon mot de passe</Link>
                </span>
            </p>

            <br />  
            <div className="flexGrow">
            
            </div>
        </div>
        </section>

    )
}

export default Login