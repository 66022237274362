// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evenementsAcceuil 
{
    width: 100%;
    background-color: lightgreen;
    color: dimgray;
    height: 65px;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Evenements/Evenements.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,4BAA4B;IAC5B,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".evenementsAcceuil \r\n{\r\n    width: 100%;\r\n    background-color: lightgreen;\r\n    color: dimgray;\r\n    height: 65px;\r\n    text-align: center;\r\n    padding: 10px;\r\n    margin-bottom: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
