import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import NativeSelect from '@mui/material/NativeSelect';

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from 'react';
import axios from '../../api/axios';

import './TableUsers.css';

const DELETEUSER_URL='/tables/delrecord/user';
const MODIFYUSER_URL='/tables/modrecord/user';
const USERS_URL = '/tables/getrecord/alluser';


export const TableUsers = () => {

    const clearHandler = async (e) => {
        e.stopPropagation();
        var name = e.currentTarget.getAttribute("value")
        var body = { "name" : name}
        await axios.post(DELETEUSER_URL,body);
    };

    const [data, setData] = React.useState([]);

    

    useEffect(() => {
        
        axios
          .post(USERS_URL)
          .then((res) => {
            setData(res.data.rows)
          })
    })

    const handleChange = async (e) => {
        e.stopPropagation();
        var role = e.target.value
        var id = e.target.id
        var body = { "role" : parseInt(role) , "id" : parseInt(id)}
        await axios.post(MODIFYUSER_URL,body);
    };

    return (
        
        <>
        
            <h4 className="primary-header Banniere">               
                Gestionnaire des Utilisateurs
            </h4>
            <br/>
        
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} size="small">
                
                <TableHead>

                    <TableRow>
                          
                        <TableCell align="left">Nom</TableCell>
                        <TableCell align="left">Mail</TableCell>
                        <TableCell align="left">Licence</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell align="left"></TableCell>

                    </TableRow>

                </TableHead>

                <TableBody>

                    {data.map((row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                               
                            <TableCell component="th" scope="row">{row.name}</TableCell>
                            <TableCell component="th" scope="row">{row.mail}</TableCell>
                            <TableCell component="th" scope="row">{row.licence}</TableCell>

                            <TableCell component="th" scope="row">
                               
                                <NativeSelect defaultValue={row.rolesclub} inputProps={{id:row.userid}} onChange={handleChange}>
                                    <option value={'1'}>USER</option>
                                    <option value={'2'}>EDITOR</option>
                                    <option value={'4'}>CONTROLEUR</option>
                                    <option value={'3'}>ADMIN</option>
                                    
                                </NativeSelect>
                                             
                            </TableCell>

                            <TableCell component="th" scope="row"  className="tabbutton">
                                <FontAwesomeIcon icon={faTrash} value={row.name} onClick={clearHandler}/>
                            </TableCell>

                        </TableRow>
                    ))}

                </TableBody>

            </Table>
            </TableContainer>
            </>
    )
}