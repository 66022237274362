import React, { useEffect, useState } from "react";
import { Form, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr' 
import './TirControle.css'
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const USERS_URL = '/tables/getrecord/alluser';
const ADDEXAMEN = '/tables/addrecord/examentircontrol';
const GETEXAMENBYID = '/tables/getrecord/examentircontrolbyid';
let nbTir = 0;
let dateDernierTir = "";

export const TirControle = ({username , socket}) => {
    
    moment.locale('FR-fr');
    const maintenant = moment(new Date()).format("YYYY-MM-DD")
    const debutAnnee = moment(new Date().setMonth(8)).day(1).format("YYYY-MM-DD")
    const finAnnee = moment(new Date().setMonth(8)).add(1,"year").day(1).format("YYYY-MM-DD")
    
    const [users, setUsers] = useState([]);

    const [user, setUser] = useState([]);
    const [distance, setDistance] = useState([]);
    const [typeArme, setTypeArme] = useState([]);
    const [calibre, setCalibre] = useState([]);
    const [observation, setObservation] = useState([]);

    const [checked0, setChecked0] = React.useState(false);
    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);
    const [checked4, setChecked4] = React.useState(false);
    const [checked5, setChecked5] = React.useState(false);
    const [checked6, setChecked6] = React.useState(false);
    const [checked7, setChecked7] = React.useState(false);
    const [checked8, setChecked8] = React.useState(false);
    const [checked9, setChecked9] = React.useState(false);
    const [checked10, setChecked10] = React.useState(false);
    const [checked11, setChecked11] = React.useState(false);
    const [checked12, setChecked12] = React.useState(false);

    const [checkeds, setCheckeds] = React.useState([false,false,false,false,false,false,false,false,false,false,false,false]);
    const [oldcontrol, setOldcontrol] = React.useState([]);
    const [tirvalid, setTirvalid] = React.useState();
    const [isRead, setIsRead] = React.useState(false);
    
    const handleChange0 = (event) => {
        setChecked0(event.target.checked);
    };

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        setCheckeds([event.target.checked, checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };
    
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
        setCheckeds([checkeds[0], event.target.checked, checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange3 = (event) => {
        setChecked3(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], event.target.checked, checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange4 = (event) => {
        setChecked4(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], event.target.checked, checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange5 = (event) => {
        setChecked5(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], event.target.checked, checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange6 = (event) => {
        setChecked6(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], event.target.checked, checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange7 = (event) => {
        setChecked7(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], event.target.checked, checkeds[7], checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange8 = (event) => {
        setChecked8(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], event.target.checked, checkeds[8], checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange9 = (event) => {
        setChecked9(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], event.target.checked, checkeds[9], checkeds[10], checkeds[11]])
    };

    const handleChange10 = (event) => {
        setChecked10(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], event.target.checked, checkeds[10], checkeds[11]])
    };

    const handleChange11 = (event) => {
        setChecked11(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], event.target.checked, checkeds[11]])
    };

    const handleChange12 = (event) => {
        setChecked12(event.target.checked);
        setCheckeds([checkeds[0], checkeds[1], checkeds[2], checkeds[3], checkeds[4], checkeds[5], checkeds[6], checkeds[7], checkeds[8], checkeds[9], checkeds[10], event.target.checked])
    };

    const superpose = (event) => {
        nbTir = 0;
        dateDernierTir = ""
        setOldcontrol({check1 : true,check2 : true,check3 : true,check4 : true,check5 : true,check6 : true,check7 : true,check8 : true,check9 : true,check10 : true,check11 : true,check12 : true});
        setDistance([]);
        setTypeArme([])
        setCalibre([]);
        setChecked0(false);
        setChecked1(false);
        setChecked2(false);
        setChecked3(false);
        setChecked4(false);
        setChecked5(false);
        setChecked6(false);
        setChecked7(false);
        setChecked8(false);
        setChecked9(false);
        setChecked10(false);
        setChecked11(false);
        setChecked12(false);
        setTirvalid('');
        setObservation('');
        setIsRead(false);
        setUser(event.target.value)
       

        axios
        .post(GETEXAMENBYID,{"id": event.target.value})
        .then((res) => {
                  
            console.log(res)
            res.data.rows ?.map((result) => {
                if ((result.date > debutAnnee) && (result.date < finAnnee))
                {
                    nbTir++;
                    dateDernierTir = result.date
                }
                setOldcontrol(result);
                setObservation(result.observations)
            })
            
        })
    };

    const handleClickCheckBox = (event) => {
        if (event.target.checked == true)
        {
            setIsRead(true)
        }
        else
        {
            setIsRead(false)
        }
    };
    
    useEffect(() => {    
        setIsRead(false);
        setDistance([]);
        setTypeArme([])
        setCalibre([]);
        setOldcontrol({check1 : true,check2 : true,check3 : true,check4 : true,check5 : true,check6 : true,check7 : true,check8 : true,check9 : true,check10 : true,check11 : true,check12 : true});
        axios
          .post(USERS_URL)
          .then((res) => {
            setUsers(res.data.rows)
          })
    },[])

    useEffect(() => {   
        let count = 0;
        checkeds?.map((check,index) => {
            if (!check)
            {
                if (index === 0 && !oldcontrol.check1)
                {
                    count++
                }
                else if (index === 1 && !oldcontrol.check2)
                {
                    count++
                }
                else if (index === 2 && !oldcontrol.check3)
                {
                    count++
                }
                else if (index === 3 && !oldcontrol.check4)
                {
                    count++
                }
                else if (index === 4 && !oldcontrol.check5)
                {
                    count++
                }
                else if (index === 5 && !oldcontrol.check6)
                {
                    count++
                }
                else if (index === 6 && !oldcontrol.check7)
                {
                    count++
                }
                else if (index === 7 && !oldcontrol.check8)
                {
                    count++
                }
                else if (index === 8 && !oldcontrol.check9)
                {
                    count++
                }
                else if (index === 9 && !oldcontrol.check10)
                {
                    count++
                }
                else if (index === 10 && !oldcontrol.check10)
                {
                    count++
                }
                else if (index === 11 && !oldcontrol.check12)
                {
                    count++
                }
                count++
            }
        })
        if ( count >= 2 )
        {
            setTirvalid("invalide")
        }
        else if ( count === 1 )
        {
            setTirvalid("reserve")
        }
        else
        {
            setTirvalid("valide")
        }

        if ( checkeds[9] === false )
        {
            setTirvalid("invalide")
        }
    
    },[checkeds])

    const handleSubmit = async (e) => {
        e.preventDefault();

        var body = {
            "tireur": user,
            "controleur": username.userid,
            "distance": distance,
            "typearme": typeArme,
            "calibre": calibre,
            "check0": checked0,
            "check1": checked1,
            "check2": checked2,
            "check3": checked3,
            "check4": checked4,
            "check5": checked5,
            "check6": checked6,
            "check7": checked7,
            "check8": checked8,
            "check9": checked9,
            "check10": checked10,
            "check11": checked11,
            "check12": checked12,
            "resultat": tirvalid,
            "observations": observation,
            "validationtireur": isRead,
            "date": maintenant
        }
        
        await axios
        .post(ADDEXAMEN, body)
        .then((res) => {
            setUser('');
            setDistance([]);
            setTypeArme([])
            setCalibre('');
            setChecked0(false);
            setChecked1(false);
            setChecked2(false);
            setChecked3(false);
            setChecked4(false);
            setChecked5(false);
            setChecked6(false);
            setChecked7(false);
            setChecked8(false);
            setChecked9(false);
            setChecked10(false);
            setChecked11(false);
            setChecked12(false);
            setTirvalid('');
            setObservation('');
            setIsRead(false);
        })
      }

    return (
        
        <>
        <Container className="contenant">  
        <Form onSubmit={handleSubmit}>

            <Row className="rowtireur">
                <Col className="col01">
                    <label className='examentirlabeluser'>
                        Tireur examiné :
                    </label> 
                </Col>
                <Col className="col02">
                    <Select className='examentirselectuser' onChange={superpose} required>       
                        {
                        users?.map((user) => {
                        return (  
                            <MenuItem value={user.userid}>{user.nom} {user.prenom}</MenuItem>        
                        )
                        })}              
                    </Select>
                </Col>
            </Row>

            <Row className="rowdistance">
                <Col className="col01">
                    <label  className='examentirlabeldistance'>
                        Distance de tir :
                    </label> 
                </Col>
                <Col className="col02">
                    <Select className='examentirselectdistance ' displayEmpty defaultValue={distance} onChange={(e) => setDistance(e.target.value)} required>                      
                        <MenuItem value='10'>10 m</MenuItem>        
                        <MenuItem value='25'>25 m</MenuItem>  
                        <MenuItem value='50'>50 m</MenuItem>  
                        <MenuItem value='100'>100 m</MenuItem>   
                        <MenuItem value='200'>200 m</MenuItem>           
                    </Select>
                </Col>
            </Row>

            <Row className="rowtypearme">
                <Col className="col01">
                    <label  className='examentirlabeltypearme'>
                        Type d'arme :
                    </label> 
                </Col>
                <Col className="col02">
                    <Select className='examentirselecttypearme' displayEmpty value={typeArme} onChange={(e) => setTypeArme(e.target.value)} required>                      
                        <MenuItem value='Arme de poing'>Arme de poing</MenuItem>        
                        <MenuItem value='Arme longue'>Arme longue</MenuItem>  
      
                    </Select>
                </Col>
            </Row>

            <Row className="rowcalibre">
                <Col className="col01">
                    <label  className='examentirlabelcalibre'>
                        Calibre :
                    </label> 
                </Col>
                <Col className="col02">
                    <input className='examentirselectcalibre' type="text" id="examentirselectcalibre" onChange={(e) => setCalibre(e.target.value)} value={calibre} required />
                </Col>
            </Row>

            
            {(nbTir != 0) ?
                    (
                        <Row className="debuttircontrol">    
                        <div>
                            Debut du Tir Contôle - Déja effectués : {nbTir} / 3
                        </div>
                        <div>
                            Date du dernier tir : {dateDernierTir}
                        </div>
                        </Row>
                    ):(
                        <Row className="debuttircontrol">    
                            Debut du Tir Contôle - Premier Tir Contrôle
                        </Row>
                    )
            }         
                
            

            <Row className='visible'>
                <Col className="visible col1">Tir avec appui</Col>
                <Col className="visible col2"><Switch checked={checked0} onChange={handleChange0} inputProps={{ 'aria-label': 'controlled' }}/></Col>           
            </Row>
            <Row className={oldcontrol.check1 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Transport de l'arme dans son véhicule</Col>
                <Col className="visible col2"><Switch checked={checked1} onChange={handleChange1} inputProps={{ 'aria-label': 'controlled' }}/></Col>           
            </Row>
            <Row className={oldcontrol.check2 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Transport de l'arme jusqu'au pas de tir</Col>
                <Col className="visible col2"><Switch checked={checked2} onChange={handleChange2} inputProps={{ 'aria-label': 'controlled' }}/></Col>               
            </Row>
            <Row className={oldcontrol.check3 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Port d'un équipement de protection auditif</Col>
                <Col className="visible col2"><Switch checked={checked3} onChange={handleChange3} inputProps={{ 'aria-label': 'controlled' }}/></Col>              
            </Row>
            <Row className={oldcontrol.check4 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Mise en place de l'arme sur la table de tir</Col>
                <Col className="visible col2"><Switch checked={checked4} onChange={handleChange4} inputProps={{ 'aria-label': 'controlled' }}/></Col>              
            </Row>
            <Row className={oldcontrol.check5 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Déplacement de l'arme sur le pas de tir</Col>
                <Col className="visible col2"><Switch checked={checked5} onChange={handleChange5} inputProps={{ 'aria-label': 'controlled' }}/></Col>             
            </Row>
            <Row className={oldcontrol.check6 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Chargement de l'arme</Col>
                <Col className="visible col2"><Switch checked={checked6} onChange={handleChange6} inputProps={{ 'aria-label': 'controlled' }}/></Col>                
            </Row>
            <Row className={oldcontrol.check7 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Le doigt ne reste pas sur la queue de détente entre chaque action de tir</Col>
                <Col className="visible col2"><Switch checked={checked7} onChange={handleChange7} inputProps={{ 'aria-label': 'controlled' }}/></Col>          
            </Row>
            <Row className={oldcontrol.check8 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Mise en sécurité de l'arme</Col>
                <Col className="visible col2"><Switch checked={checked8} onChange={handleChange8} inputProps={{ 'aria-label': 'controlled' }}/></Col>             
            </Row>
            <Row className={oldcontrol.check9 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Canon toujours dirigé vers les cibles</Col>
                <Col className="visible col2"><Switch checked={checked9} onChange={handleChange9} inputProps={{ 'aria-label': 'controlled' }}/></Col>             
            </Row>
            <Row className={oldcontrol.check10 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Tout les tirs sont dans la cible ( au moins 18 impacts visibles )</Col>
                <Col className="visible col2"><Switch checked={checked10} onChange={handleChange10} inputProps={{ 'aria-label': 'controlled' }}/></Col>        
            </Row>
            <Row className={oldcontrol.check11 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Aucune mise en danger</Col>
                <Col className="visible col2"><Switch checked={checked11} onChange={handleChange11} inputProps={{ 'aria-label': 'controlled' }}/></Col>           
            </Row>
            <Row className={oldcontrol.check12 ? 'visible' : 'visible yellow'}>
                <Col className="visible col1">Respect des signaux sonores et lumineux</Col>
                <Col className="visible col2"><Switch checked={checked12} onChange={handleChange12} inputProps={{ 'aria-label': 'controlled' }}/></Col>         
            </Row>
                
            <Row className="rowvalidationcontrol">
                <Col>
                <label  className='rowvalidationcontrollabel'>
                    Resultat du Tir Contrôle :
                </label>   
                </Col>
                <Col>
                    {(tirvalid === 'valide') ?
                    (
                        <Button className="rowvalidationcontrolbutton" variant="success" disabled>Valide</Button>          
                    ):(

                        (tirvalid === 'reserve') ?
                        (
                            <Button className="rowvalidationcontrolbutton" variant="warning" disabled>Reserve</Button>          
                        ):(
                            
                            <Button className="rowvalidationcontrolbutton" variant="danger" disabled>Invalide</Button>     
                        )    
                    )}
                </Col>
            </Row>  

            <Row className="rowobservationlabel">
                <label  className='Observationslabel'>
                    Observations :
                </label>   
            </Row>  
            <Row className="rowobservation">     
                <textarea 
                    className='Observationsarea'
                    type="text"
                    id="Observations"
                    onChange={(e) => setObservation(e.target.value)}
                    value={observation}
                    required
                />
            </Row>
        
            <Row className="rowvalidationcontrol">
                <Col>
                <label  className='rowvalidationcontrollabel'>
                    En tant que tireur j'ai pris connaissance de ces informations :
                </label>   
                </Col>
                <Col>
                    <Checkbox className='big-checkbox' onClick={handleClickCheckBox} required></Checkbox>
                </Col>
            </Row>  
                        
            <Button className='buttonFormLoginTirControl' type="submit" disabled={!isRead} >Enregistrement</Button>

        </Form>
        </Container>
        </>
    )

}
