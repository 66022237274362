import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { useRef, useState, useEffect } from 'react';
import axios from '../../api/axios';

const GETALLCARROUSSEL = '/tables/getAllrecord/carroussel'

export const Carousell = () => {

    const [images, setImages] = React.useState([]);

    useEffect(() => {
        
        axios
          .post(GETALLCARROUSSEL)
          .then((res) => {
            setImages(res.data.rows)
        })
    })

    return (
        
        <div className="div-acceuil">
            <Carousel fade className="carroussel">

            {images.map((image) => {
                return (
                  
                    <Carousel.Item className="adapt">
                        <img className="imgfit" src={image.images}/>
                    </Carousel.Item>
                )
            })}
              
            </Carousel>
                        
            <div className="textacceuil">
                Le club est une association régie par la loi du 1er juillet 1901. <br/>
                Il est agréé par le Ministère de la Jeunesse et des Sports sous le n°9603S512. <br/>
                Il est affilié à la Fédération Française de Tir sous le n°030300202.
            </div>

            </div>
    )
}