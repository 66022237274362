import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux'
import { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axios from '../../api/axios';
import './Users.css'
import avatarDefault from '../../media/images/people.png'
import Resizer from "react-image-file-resizer";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const GETUSER_URL = '/tables/getrecord/userlicence';
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const MAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\.[a-z]{2,4}$/;
const MODIFYUSER_URL = '/tables/modrecord/userlicence';
const GETEXAMENBYID = '/tables/getrecord/examentircontrolbyid';

const Profil = () => {

    const users = useSelector((state) => state.users)
    const initialized = useRef(false);

    const [infoUser, setInfoUser] = useState('');
    const errRef = useRef();
    const sucRef = useRef();
    const fileRef = useRef();
    const userRef = useRef();
    const nomRef = useRef();
    const mailRef = useRef();
    const prenomRef = useRef();
    const [validName, setValidName] = useState(false);
    const [user, setUser] = useState('');
    const [userFocus, setUserFocus] = useState(false);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [mail, setMail] = useState('');
    const [validMail, setValidMail] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);
    const [role, setRole] = useState(false);
    const [date, setDate] = useState(false);
    const [licence, setLicence] = useState(false);
    const [avatar, setAvatar] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [sucMsg, setSucMsg] = useState('');

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true                    
            getUser();         
        }
    },[])
    
    useEffect(() => {
        const timeout = setTimeout(() => {    
            setSucMsg('');
            setErrMsg('');
          }, 1000);
          return () => { clearTimeout(timeout) }
    }, [errMsg , sucMsg])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    useEffect(() => {
        setSucMsg('');
    }, [])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setValidMail(MAIL_REGEX.test(mail));
    }, [mail])

    useEffect(() => {
        setUser(infoUser.name);
        setNom(infoUser.nom);
        setPrenom(infoUser.prenom);
        setPwd(infoUser.password);
        setMatchPwd(infoUser.password);
        setMail(infoUser.mail);
        setRole(infoUser.role);
        setLicence(infoUser.licence);
        setAvatar(infoUser.avatar);
        
    }, [infoUser])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    const getUser = async (e) => {

        axios
          .post(GETUSER_URL,{"name" : users.name , "licence" : users.licence })
          .then((res) => {
            if (res?.data.rowCount > 0)
            {
                setInfoUser(res?.data.rows[0]);
                axios
                .post(GETEXAMENBYID,{"id" : users.userid})
                .then((res) => {
                    if (res.data.rowCount === 0)
                    {
                        setDate("Aucun Tir Contrôle");
                    }
                    else
                    {
                        setDate(res?.data.rows[0].date);
                    }
                  
                })
            }
            
        })   
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.nativeEvent.submitter.id === '')
        {
            const v1 = USER_REGEX.test(user);
            const v2 = PWD_REGEX.test(pwd);
    
            if (!v1 || !v2) {
                setErrMsg("Entrée Invalide");
                return;
            }
    
            var body = {"id" : infoUser.userid , "name" : user,"password" : pwd, "nom" : nom, "prenom" : prenom ,  "mail" : mail , "avatar" : avatar}
            try 
            {
                axios
                    .post(MODIFYUSER_URL,body)
                    .then((res) => {
                        setSucMsg('Modifications Enregistrées')
                }) 
            } 
            catch (err) 
            {
                if (!err?.response) 
                {
                    setErrMsg('No Server Response');
                } 
                else 
                {
                    setErrMsg('Problème à l enregistrement')
                }
                errRef.current.focus();
            }  
        }  
    }

    const resizeFile = (file) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(file,100,100,"JPEG",100,0,(uri) => {
            resolve(uri);
        },
        "base64"
        );
    });

    const imageUpload = async (e) => {
        const file = e.target.files[0];
        const image = await resizeFile(file);
        setAvatar(image)
    };

    const handleClickImage = () => {
        const fileElem = fileRef.current;
        if (fileElem) {
          fileElem.click();
        }
    }

    return (

        <>
            <h4 className="primary-header Banniere">               
                Profil Utilisateur
            </h4>

            <p ref={sucRef} className={sucMsg ? "sucmsg" : "offscreen"} aria-live="assertive">{sucMsg}</p>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
            <Container className="fill margin10">

            

            <form onSubmit={handleSubmit}>

                
            <Row>
                <Col className='userForm'>
                    <label htmlFor="username">
                        Identifiant:
                        <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                        <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                        aria-invalid={validName ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                    />
                    <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                        4 à 24 caractères.<br />
                        Doit commencer par une lettre<br />
                        Lettres, chiffres, underscores, autorisés.
                    </p>

                    <div className='infoInputBR'></div>

                    <label htmlFor="nom">
                    Nom:
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="nom"
                        ref={nomRef}
                        autoComplete="off"
                        onChange={(e) => setNom(e.target.value)}
                        value={nom}
                        required
                    />

                    <div className='infoInputBR'></div>

                    <label htmlFor="prenom">
                    Prénom:
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="prenom"
                        ref={prenomRef}
                        autoComplete="off"
                        onChange={(e) => setPrenom(e.target.value)}
                        value={prenom}
                        required
                    />

                    <div className='infoInputBR'></div>

                    <label htmlFor="password">
                        Mot de passe:
                        <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                        <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                        aria-invalid={validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                    />
                    
                    <div className='infoInputBR'></div>

                    <label htmlFor="confirm_pwd">
                    Confirmation:
                    <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInputProfil'
                    type="password"
                    id="confirm_pwd"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                />
                <br/>
                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    8 to 24 caratères.<br />
                    Doit inclure lettres majuscules et minuscules, chiffre(s) et un caractère spécial.<br />
                    Caractères spéciaux autorisés: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>       
                </p>
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    les mots de passe doivent correspondre.
                </p>

                


                </Col>

                <Col className='userForm'>

                    <label htmlFor="avatar">
                    Avatar:
                    </label>
                    <br/>
                    <img
                        className='infoInputProfilAvatar'
                        id="avatar"
                        autoComplete="off"
                        src={avatar ? avatar : avatarDefault}
                        required
                        disabled
                    />
                    <br/>
                    <button id="imageFileButton" type="text" className='infoInputProfilButton' onClick={handleClickImage}>Choisir image</button>
                    <input
                        className='imagebutton'
                        type="file" 
                        ref={fileRef}
                        id="imageFile" 
                        name='imageFile' 
                        onChange={imageUpload} 
                        style={{display: 'none'}}
                    />
                    
                    
                    <br/>


                    <label htmlFor="role">
                    Rôle:
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="role"
                        autoComplete="off"
                        value={role}
                        required
                        disabled
                    />
                    <br/>
                    <label htmlFor="licence">
                    Licence:
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="licence"
                        autoComplete="off"
                        value={licence}
                        required
                        disabled
                    />
                    <br/>
                    <label htmlFor="tircontrol">
                    Dernier Tir contrôle:
                    </label>
                    <br/>
                    <input
                        className='infoInputProfil'
                        type="text"
                        id="tircontrol"
                        autoComplete="off"
                        value={date}
                        required
                        disabled
                    />
                    <br/>
                </Col>

            </Row>

            <Row>

                <div className='infoInputBR'></div>
                <label htmlFor="mail">
                    Adresse mail:
                    <FontAwesomeIcon icon={faCheck} className={validMail ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validMail || !mail ? "hide" : "invalid"} />
                </label>
                <br/>
                <input
                    className='infoInputProfil'
                    type="text"
                    id="mail"
                    ref={mailRef}
                    autoComplete="off"
                    onChange={(e) => setMail(e.target.value)}
                    value={mail}
                    required
                    aria-invalid={validMail ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setMailFocus(true)}
                    onBlur={() => setMailFocus(false)}
                />
                <br/>
                <p id="uidnote" className={mailFocus && mail && !validMail? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                    Doit etre au bon format d'adresse mail <br />
                    ex : jeandupont@google.fr
                </p>

            </Row>

            <div className='infoInputBR'></div>

            <button className='buttonFormLoginProfil' disabled={!validName || !validPwd || !validMatch || !validMail  ? true : false}>Enregistrement</button>


            </form>

            </Container>
        </>
    )
}

export default Profil