// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoMeteo {
    margin-top: 10px;
    width: 70px;
    margin-left: 10px;
    margin-bottom: -10px;
}

.card1 {
    background-color: rgba(36, 94, 177,0.5);
    height: 110px;
}

.meteoCard {
    position: absolute;
    top: 85px;
    right: 5px;
    width: 220px;
}

.meteoText {
    color: white;
    font-size: 11px;
}

.meteoIcon {
    color: white;
    font-size: 15px;
}
.meteoMenu {
    margin-bottom: 3px;
}

.colText {
    font-size: smaller;
}

.colIcon {
    text-align: center;
    margin: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/Meteo/Meteo.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,uCAAuC;IACvC,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".logoMeteo {\r\n    margin-top: 10px;\r\n    width: 70px;\r\n    margin-left: 10px;\r\n    margin-bottom: -10px;\r\n}\r\n\r\n.card1 {\r\n    background-color: rgba(36, 94, 177,0.5);\r\n    height: 110px;\r\n}\r\n\r\n.meteoCard {\r\n    position: absolute;\r\n    top: 85px;\r\n    right: 5px;\r\n    width: 220px;\r\n}\r\n\r\n.meteoText {\r\n    color: white;\r\n    font-size: 11px;\r\n}\r\n\r\n.meteoIcon {\r\n    color: white;\r\n    font-size: 15px;\r\n}\r\n.meteoMenu {\r\n    margin-bottom: 3px;\r\n}\r\n\r\n.colText {\r\n    font-size: smaller;\r\n}\r\n\r\n.colIcon {\r\n    text-align: center;\r\n    margin: 1px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
