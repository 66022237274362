import { useState, useEffect, createRef , useRef, memo} from 'react';
import moment from 'moment';
import './TirControle.css'
import frLocale from '@fullcalendar/core/locales/fr';
import axios from 'axios';
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import Data from "./Data";
import emailjs from '@emailjs/browser'

const computeDurations = (mode) => {
    return {
        slotDuration: { minutes: 30 },
        slotLabelFormat: [{hour: 'numeric',minute: '2-digit',}],
        duration: { days: 1 },
    };
};

const ExternalEvent = memo(({ event }) => {
    let elRef = useRef(null);
  
    useEffect(() => {
      let draggable = new Draggable(elRef.current, {
        eventData: function () {
          return { ...event, create: true };
        }
      });
  
      // a cleanup function
      return () => draggable.destroy();
    });
  
    return (
      <div
        ref={elRef}
        className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2 reservationecole"
        title={event.title}
        style={{
          backgroundColor: event.color,
          borderColor: event.color,
          cursor: "pointer",
          borderRadius : "5px"
        }}
      >
        <div className="fc-event-main ecole">
          <div>
            <strong>{event.title}</strong>
          </div>
        </div>
      </div>
    );
  });

  const GETPERMANENCECONTROL_URL = '/tables/getrecord/permanencecontrol';
  const REGISTERTIRCONTROL_URL = '/tables/addrecord/tircontrol';
  const DEREGISTERTIRCONTROL_URL = '/tables/delrecord/tircontrol';
  const GETTIRCONTROL_URL = '/tables/getrecord/tircontrol';

let newArr = [];
let controleurs = new Map();
let mail;

const TirControl = ({username , socket}) => {

    const formRef = useRef();
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [controleurNom, setControleurNom] = useState('');
    const [controleurPrenom, setControleurPrenom] = useState('');
    const [controleurMail, setControleurMail] = useState('');
    const [reservationDate, setReservationDate] = useState('');
    const [reservationHeureDebut, setReservationHeureDebut] = useState('');
    const [reservationHeureFin, setReservationHeureFin] = useState('');
    const [reservationNom, setReservationNom] = useState('');
    const [reservationPrenom, setReservationPrenom] = useState('');

    const affichage = username.nom;

    moment.locale('FR-fr');
    const initialized = useRef(false);
    const year = moment(new Date()).format("YYYY")
    const [date] = useState(new Date());
    let AllowedConstraints = ""
    const calendarRef = createRef();
    const { duration, slotDuration, slotLabelFormat } = computeDurations();
    const [resourcesArea, setResourcesArea] = useState([]);
    const [resources, setResources] = useState([]);
    const [slotMinTime,setSlotMinTime] = useState("09:00:00");
    const [slotMaxTime,setSlotMaxTime] = useState("17:00:00");
    const [state] = useState({externalEvents: [{ title: "Reservation", constraint: AllowedConstraints, color: "#FFA500", id: Math.floor(Math.random()*200000), duration: '0:30' }]});
    const [events, setEvents] = useState([]);

    useEffect(() => {
        
        socket.on('messageResponse', (data) => {

            if ( data.text === 'addCalendrier' )
            {
                setEvents((events) => [...events , { 
                    id: data.event.id,
                    title: data.event.title,
                    color: data.event.color,
                    start: data.event.start,
                    end : data.event.end,
                    resourceId : data.event.resourceid,
                }]);
            }
            else if ( data.text === 'delCalendrier' )
            {
                if (newArr.findIndex(item => item.id === parseInt(data.event.id)) !== -1)
                {
                    newArr.splice(newArr.findIndex(item => item.id === parseInt(data.event.id)), 1)
                    setEvents(newArr);
                }   
                setEvents(newArr);              
            } 

        });

    }, []);

    useEffect(() => {
        newArr = [...events];
    }, [events]);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true          
            setResourcesArea(Data.resourceAreaColumns);
            loadResources();
            loadDatas();
            loadJoursFeries();
          }   
    }, []);

    const loadJoursFeries = async () => {

        axios
          .get('https://calendrier.api.gouv.fr/jours-feries/metropole/'+year+'.json')
          .then((res) => {
            Object.entries(res.data).forEach(([key, value]) => {
                setEvents((events) => [...events , 
                {
                    id:Math.floor(Math.random()*500000),
                    title:value,
                    start:key,
                    color:'#FF000033',
                    display: 'background'
                }])        
            });
        })
    }

    const loadDatas = async () => {
        
        axios
          .post(GETTIRCONTROL_URL)
          .then((res) => { 
            Object.entries(res.data.rows).forEach(([key, value]) => {
                setEvents((events) => [...events , 
                {
                    id : value.tircontrolid,
                    title: value.title,
                    start : value.start,
                    end : value.end,
                    color: '#FFA500',
                    resourceId : value.resourceid         
                }]);                    
            })                            
          })     
    }

    const loadResources = async () => {
     
        axios
          .post(GETPERMANENCECONTROL_URL,{'type' : 'CDT'})
          .then((res) => {          
            Object.entries(res.data.rows).forEach(([key, value]) => {
                
                setResources((resources) => [...resources , 
                {
                    id: "r"+value.id,                  
                    title: "☆ " + value.name + " " + value.nom,
                }]);  

                var id = "availableForControl"+ value.permanenceid
                controleurs.set("r"+value.id , [value.nom, value.prenom,value.mail])

                setEvents((events) => [...events , 
                {
                    id : id,
                    //title: "Controleur : " + value.title,
                    start : value.start + "T" + value.heuredebut,
                    end : value.start + "T" + value.heurefin,
                    color: '#00ff00',
                    overlap: true,  
                    display: 'background',    
                    resourceId: ["r"+value.id],    
                }]);               
                AllowedConstraints = AllowedConstraints + id + ','
                
            }); 
            AllowedConstraints = AllowedConstraints.slice(0,AllowedConstraints.length-1)                     
        })
    }

    const datesRender = ( dateInfo ) => {
        const jour = (moment(dateInfo.start)).format("d")
        if (jour === '6')
        {
            setSlotMinTime("14:00:00")
            setSlotMaxTime("17:00:00")
        }
        else if (jour === '0')
        {
            setSlotMinTime("09:00:00")
            setSlotMaxTime("12:00:00")
        }
        else if (jour === '1')
        {
            setSlotMinTime("09:00:00")
            setSlotMaxTime("12:00:00")
        }
    }

    const overlapAllowed = (stillEvent,movingEvent) => {
        if ( String(stillEvent.id).startsWith("availableForControl") )
        {
            return true;
        }
    }

    const handleEventReceive = (eventInfo) => {

        eventInfo.event._def.title = affichage
        eventInfo.event._def.id = Math.floor(Math.random()*50000)
        const eventToState = {
            id: eventInfo.event._def.id,
            title: affichage,
            color: eventInfo.event.backgroundColor,
            start: eventInfo.event.startStr,
            end : eventInfo.event.endStr,
            resourceid : eventInfo.event._def.resourceIds[0]
        }
        var body = {"id" : eventToState.id, "title" : username.userid , "color" : eventToState.color, "start" : eventToState.start, "end" : eventToState.end , "resourceid" : eventToState.resourceid};
        mail = {"user" : username , "event" : eventToState , "controleur" : controleurs.get(eventToState.resourceid)}
        registerTirControl(body) 
        formRef.current.requestSubmit(); 
    };

    const registerTirControl = async (body) =>
    {
        await axios.post(REGISTERTIRCONTROL_URL,body);
        body.title = affichage
        socket.emit('message', { text: 'addCalendrier' , event : body });  
        
    }

    const eventClick = ({event}) =>
    {
        if (event.title === username.nom)
        {         
            setEvents(handleRemoveItem(event))
        }  
    }

    const handleRemoveItem = (e) => {
        const newArr = [...events];
        newArr.splice(newArr.findIndex(item => item.id === parseInt(e.id)), 1)
        var body = {"id" : e.id };
        deregisterTirControl(body)
        return newArr
    };

    const deregisterTirControl = async (body) =>
    {
        socket.emit('message', { text: 'delCalendrier' , event : body }); 
        await axios.post(DEREGISTERTIRCONTROL_URL,body);  
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.target.controleur_nom.value=mail.controleur[0];
        e.target.controleur_prenom.value=mail.controleur[1];
        e.target.controleur_mail.value=mail.controleur[2];

        const dateev = (moment(mail.event.start)).format("DD/MM/YYYY");
        const heuredev = (moment(mail.event.start)).format("HH:mm");
        const heurefev = (moment(mail.event.end)).format("HH:mm");

        e.target.reservation_date.value=dateev;
        e.target.reservation_heuredebut.value=heuredev;
        e.target.reservation_heurefin.value=heurefev;
        e.target.reservation_nom.value=mail.user.nom;
        e.target.reservation_prenom.value=mail.user.prenom;

        e.target.subject.value="Star St-Pourçinoise Reservation"
        
        sendEmail(e)
    }

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_nvvytvk' , 'template_mif52ag' , e.target , 'N5rkV6C7dY-4mluUT');
    }

    return (
    
        <>
            <h4 className="primary-header Banniere">               
                Réservations Tir Contôle
            </h4>
          
            <div id="external-events" className='externalevents externaleventsTirEcole'>
            <div className='textresa'>
            Faites un glisser/deposer de votre réservation a partir du rectangle orange
            </div>
            {state.externalEvents.map(event => (
                <ExternalEvent className="externalevent" key={event.id} event={event} />
            ))}
            </div>

            <div className='calendarTir'>

                <FullCalendar
                    selectable={true}
                    locale={frLocale}
                    ref={calendarRef}
                    plugins={[resourceTimelinePlugin , interactionPlugin]}
                    initialView="resourceTimelineDay"
                    headerToolbar={{
                        left: 'next today',
                        center: 'title',
                        right: ''
                    }}
                    slotDuration={slotDuration}
                    slotLabelFormat={slotLabelFormat}
                    duration={duration}
                    slotMinTime={slotMinTime}
                    slotMaxTime={slotMaxTime}
                    slotEventOverlap={false}
                    hiddenDays={[2,3,4,5]}
                    nowIndicator={true}
                    //editable={true}
                    events={events}
                    lazyFetching={false}               
                    resources={resources}
                    resourceAreaColumns={resourcesArea} 
                    datesSet={datesRender}
                    eventOverlap={overlapAllowed}
                    eventReceive={handleEventReceive}
                    eventClick= {eventClick}
                    now={date}
                />

                <form ref={formRef} onSubmit={handleSubmit} type="hidden">
                    <input
                        className='infoInput'
                        type="hidden"
                        id="controleur_nom"
                        name="controleur_nom"
                        value={controleurNom}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="controleur_prenom"
                        name="controleur_prenom"
                        value={controleurPrenom}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="controleur_mail"
                        name="controleur_mail"
                        value={controleurMail}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="reservation_date"
                        name="reservation_date"
                        value={reservationDate}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="reservation_heuredebut"
                        name="reservation_heuredebut"
                        value={reservationHeureDebut}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="reservation_heurefin"
                        name="reservation_heurefin"
                        value={reservationHeureFin}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="reservation_nom"
                        name="reservation_nom"
                        value={reservationNom}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="reservation_prenom"
                        name="reservation_prenom"
                        value={reservationPrenom}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="subject"
                        name="subject"
                        value={subject}
                    />
                    <input
                        className='infoInput'
                        type="hidden"
                        id="message"
                        name="message"
                        value={message}
                    />

                    <button className='buttonFormLogin' hidden="hidden">Envoyer</button>
                </form>

            </div>

        </>
    );




};




export default TirControl;