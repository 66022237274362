import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect } from 'react';
import axios from '../../api/axios';

import './TableLicences.css';

const REGISTERLICENCE_URL = '/tables/addrecord/licence';
const DELETELICENCE_URL = '/tables/delrecord/licence'
const VERIFY_URL = '/tables/verifylicence';
const LICENCE_REGEX = /^[0-9]{8}$/;
const LICENCES_URL = '/tables/getrecord/alllicence';

export const TableLicences = () => {

    const errRef = useRef();
    const licenceRef = useRef();
    const [licence, setLicence] = useState('');
    const [validLicence, setValidLicence] = useState(false);
    const [licenceFocus, setLicenceFocus] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [data, setData] = React.useState([]);


    useEffect(() => {
        setValidLicence(LICENCE_REGEX.test(licence));
    }, [licence])

    useEffect(() => {
        setErrMsg('');
    }, [licence])

    const clearHandler = async (e , row) => {
        e.stopPropagation();
        var licence = e.currentTarget.getAttribute("value")
        var body = { "licence" : licence}
        await axios.post(DELETELICENCE_URL,body);
    };

    useEffect(() => {
        
        axios
          .post(LICENCES_URL)
          .then((res) => {
            setData(res.data.rows)
          })
    })

    const handleSubmit = async (e) => {

        e.preventDefault();
        var body = { "licence" : licence}

        try 
        {
            const response = await axios.post(VERIFY_URL,body);
            

            if (response.data.rowCount > 0)
            {
                if (response.data.rows[0].licence != licence)
                {
                    const response = await axios.post(REGISTERLICENCE_URL,body);
            
                    setLicence('');
                }
                else
                {
                    setErrMsg('Numéro de Licence déjà utilisée')
                }
                
            }
            else
            {
                const response = await axios.post(REGISTERLICENCE_URL,body);        
                setLicence('');
            }
        } 
        catch (err) 
        {
            if (!err?.response) 
            {
                setErrMsg('No Server Response');
            } 
            else 
            {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        } 
    }

    return (
        
        <>
            <h4 className="primary-header Banniere">               
                Gestionnaire des licences pouvant s'enregistrer sur le site
            </h4>
            <br/>
        <Container className='licenceContainer'>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <Row>

                <Col className='colLicence'>
                    <div className='licenceText'>
            
                    <form onSubmit={handleSubmit}>
             
                        <label htmlFor="licence">
                            Numéro de licence :
                            <FontAwesomeIcon icon={faCheck} className={validLicence ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validLicence || !licence ? "hide" : "invalid"} />
                        </label>
                        <br/>
                        <input
                            className='infoInput'
                            type="text"
                            id="licence"
                            ref={licenceRef}
                            autoComplete="off"
                            onChange={(e) => setLicence(e.target.value)}
                            value={licence}
                            required
                            aria-invalid={validLicence ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setLicenceFocus(true)}
                            onBlur={() => setLicenceFocus(false)}
                        />
                        <br/>
                        <p id="uidnote" className={licenceFocus && licence && !validLicence? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                            Doit etre au bon format de licence FFTir <br />
                            ex : 12345678
                        </p>
                        <button className='buttonFormLogin' disabled={!validLicence  ? true : false}>Enregistrement</button>
                    </form>
                </div>
                
                </Col>

                <Col className='colLicence'>

                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} size="small">
                
                <TableHead>

                    <TableRow>
                          
                        <TableCell align="left">Licence</TableCell>
                        <TableCell align="left">Utilisation</TableCell>
                        <TableCell align="left"></TableCell>

                    </TableRow>

                </TableHead>

                <TableBody>

                    {data.map((row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                               
             
                            <TableCell component="th" scope="row">{row.licence}</TableCell>
                            <TableCell component="th" scope="row" >
                                <Checkbox color="primary" checked={row.used} />
                            </TableCell>
                            <TableCell component="th" scope="row"  className="tabbutton">
                                <FontAwesomeIcon icon={faTrash}  value={row.licence} onClick={clearHandler}/>
                            </TableCell>

                        </TableRow>
                    ))}

                </TableBody>

                </Table>
                </TableContainer>

                </Col>

                

            </Row>

        </Container>

        </>
    )
}
