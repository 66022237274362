import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr' 

import './Evenements.css'

const GETEVENEMENTS_URL = '/tables/getrecord/evenementsacceuil';

library.add(fas)

export const Evenements = () => {
    
    moment.locale('FR-fr');
    const maintenant = moment(new Date()).format("YYYY-MM-DD")

    const [data, setData] = useState([]);

    useEffect(() => {
        var body = {"date": maintenant}
        axios
          .post(GETEVENEMENTS_URL,body)
          .then((res) => {
            setData(res.data.rows)
          })
    },[])

    return (


        
            
        <div>
            


        {
        (data === undefined) ? (

            <div></div>
        ):
        (
        (data.length === 0) ? (

            <div></div>
        ):( 
            <div className="evenementsAcceuil">
            <div className="evenementAffichage"> Prochain évènement : {data[0].titreevenement}</div>
            <div className="evenementAffichage"> Le {(moment(data[0].start)).format("dddd")} {(moment(data[0].start)).format("Do")} {(moment(data[0].start)).format("MMMM")}   à {data[0].heuredebut} </div>
            </div>
        )
        )
        }

        </div>
        
    )

}